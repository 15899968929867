import { getRecord, insertRecord, updateRecord } from "@/utils/helpers/data";

export interface User {
  id: string;
  created_at?: string;
  notification_tokens: string | null;
}

/**
 * Retrieves a single user by its ID.
 * @param userId - The ID of the user to retrieve.
 * @returns The requested user or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getUser = async (
  userId: string | null | undefined,
): Promise<User | null> => {
  if (!userId) return null;
  return getRecord<User>("users", "id", userId);
};
/**
 * Creates a new user record in the database.
 * @param user - The user data to insert.
 * @returns The created user or null if insertion failed.
 * @throws Will throw an error if the insertion fails.
 */
export const createUser = async (user: User): Promise<User | null> => {
  return insertRecord<User>("users", user);
};

/**
 * Uploads a notification token for a specific user.
 * @param id - The ID of the user.
 * @param token - The notification token to upload.
 * @returns The updated user, or null if the update failed.
 * @throws Will throw an error if the update fails.
 */
export const uploadNotificationToken = async (
  id: string | null | undefined,
  token: string | null | undefined,
): Promise<User | null> => {
  if (!id || !token) return null;

  try {
    const user = await getUser(id);

    if (user) {
      const existingTokens = user.notification_tokens
        ? user.notification_tokens.split(",")
        : [];

      if (existingTokens.includes(token)) return null;

      existingTokens.push(token);

      const tokens = existingTokens.join(",");

      return updateRecord<User>("users", "id", id, {
        notification_tokens: tokens,
      });
    }

    return null;
  } catch (error: any) {
    console.error("Error uploading notification token:", error.message);
    return null;
  }
};
