import {
  IonContent,
  IonIcon,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import React, { useEffect } from 'react';

import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import WeeklyReportDataItems from '@/components/dashboard/profile/weeklyReports/weeklyReportDataItems/WeeklyReportDataItems';
import { useWeeklyReports } from '@/contexts/WeeklyReportsContext';
import useDimensions from '@/hooks/useDimensions';
import useToast from '@/hooks/useToast';
import {
  WEEKLY_REPORT_DEFAULT_CHART_HEIGHT,
  WEEKLY_REPORT_DEFAULT_CHART_MAX_WIDTH,
  WEEKLY_REPORT_DEFAULT_CHART_WIDTH,
  WEEKLY_REPORT_DEFAULT_DENTIST,
  WEEKLY_REPORT_SUBSCRIPTION_TYPES
} from '@/utils/constants';
import {
  arrowBackOutline,
  calendarOutline,
  cashOutline,
  eyeOffOutline,
  timerOutline
} from 'ionicons/icons';
import moment from 'moment';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis } from 'recharts';

import './WeeklyReports.css';

interface WeeklyReportsProps {
  isOpen: boolean;
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
  dentistId?: string;
}

const WeeklyReports: React.FC<WeeklyReportsProps> = ({
  isOpen,
  onClose,
  presentingElement,
  dentistId
}) => {
  const displayToast = useToast();
  const {
    weeklyReportData,
    startDates,
    dentists,
    loading,
    loaded,
    selectedStartDate,
    setSelectedStartDate,
    selectedDentist,
    setSelectedDentist,
    estimatedRevenue,
    chartData,
    totalAppointments,
    noShowRate,
    lateRate
  } = useWeeklyReports();
  const { width } = useDimensions();

  const isClinicReport =
    weeklyReportData?.reportType === WEEKLY_REPORT_SUBSCRIPTION_TYPES.CLINIC;

  const reportDataItems = [
    ...(isClinicReport
      ? [
          {
            icon: cashOutline,
            title: 'Estimated Revenue',
            value: `₱${estimatedRevenue.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}`
          }
        ]
      : []),
    {
      icon: calendarOutline,
      title: 'Total Appointments',
      value: totalAppointments
    },
    {
      icon: eyeOffOutline,
      title: 'No-shows',
      value: `${noShowRate}%`
    },
    {
      icon: timerOutline,
      title: 'Late Patients',
      value: `${lateRate}%`
    }
  ];

  const formatWeek = (startDate: string) => {
    const startOfWeek = moment(startDate);
    const endOfWeek = startOfWeek.clone().add(6, 'days');
    return `${startOfWeek.format('MMMM D')} - ${endOfWeek.format('D, YYYY')}`;
  };

  const renderBarLabel = ({ x, y, width, height, value }: any) => {
    if (
      value === Math.max(...chartData.map((data) => data.Appointments)) &&
      value !== 0
    ) {
      return (
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill="#cbc9ff"
          textAnchor="middle"
        >
          {value}
        </text>
      );
    }

    return <></>;
  };

  useEffect(() => {
    if (!dentistId || !isOpen) {
      onClose();
      return;
    }

    if (startDates.length === 0 && !loading && loaded) {
      onClose();
      displayToast({
        message:
          'You currently have no weekly reports available. Please check back next week for updates.',
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'tabBar'
      });
    }
  }, [startDates, loading, loaded, dentistId, isOpen]);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <div id="weeklyReports">
            <Logo leftIcon={arrowBackOutline} onClickLeftIcon={onClose} />
            <div id="weeklyReportsSelects">
              <IonSelect
                placeholder="Select Week"
                onIonChange={(e) => setSelectedStartDate(e.detail.value)}
                value={selectedStartDate}
                label="Week"
              >
                {startDates.map((date) => (
                  <IonSelectOption key={date} value={date}>
                    {formatWeek(date)}
                  </IonSelectOption>
                ))}
              </IonSelect>
              {isClinicReport && (
                <IonSelect
                  placeholder="Select Dentist"
                  onIonChange={(e) => setSelectedDentist(e.detail.value)}
                  value={selectedDentist}
                  label="Dentist"
                >
                  {dentists.map((dentist) => (
                    <IonSelectOption key={dentist} value={dentist}>
                      {dentist === WEEKLY_REPORT_DEFAULT_DENTIST
                        ? dentist
                        : `Dr. ${dentist}`}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
            </div>
            <Spacer top={24}>
              <div id="weeklyReportsChart">
                {chartData && (
                  <BarChart
                    width={
                      Math.min(
                        width ?? WEEKLY_REPORT_DEFAULT_CHART_WIDTH,
                        WEEKLY_REPORT_DEFAULT_CHART_MAX_WIDTH
                      ) - 32
                    }
                    height={WEEKLY_REPORT_DEFAULT_CHART_HEIGHT}
                    data={chartData}
                    id="weeklyReportsChart"
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis
                      dataKey="Day"
                      tickFormatter={(value) => value.slice(0, 1)}
                      tickLine={false}
                    />
                    <Tooltip />
                    <Bar
                      dataKey="Appointments"
                      fill="#534292"
                      label={renderBarLabel}
                      radius={[8, 8, 0, 0]}
                    />
                  </BarChart>
                )}
              </div>
            </Spacer>
            <Spacer top={24} bottom={42}>
              <div id="weeklyReportsData">
                {reportDataItems.length > 0 && (
                  <WeeklyReportDataItems items={reportDataItems} />
                )}
              </div>
            </Spacer>
          </div>
          <IonLoading
            isOpen={loading}
            spinner="crescent"
            message="Loading..."
          />
        </Padding>
      </IonContent>
    </IonModal>
  );
};

export default WeeklyReports;
