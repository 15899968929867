import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { App } from '@capacitor/app';
import { RealtimeChannel } from '@supabase/supabase-js';

import { useDentist } from '@/contexts/DentistContext';
import { Patient, getPatient } from '@/data/Patients';
import {
  Request,
  getAppointmentsForDay,
  getRequestsForDashboard
} from '@/data/Requests';
import useToast from '@/hooks/useToast';
import {
  FETCH_REQUESTS_TIMEOUT_IN_MS,
  REQUEST_EXPIRATION_BUFFER_IN_MINUTES,
  REQUEST_STATUS_OPTIONS
} from '@/utils/constants';
import { supabase } from '@/utils/supabase';
import { RequestStatus } from '@/utils/types';
import moment from 'moment';

interface RequestsContextProps {
  loading: boolean;
  loaded: boolean;
  requests: Request[];
  setRequests: React.Dispatch<React.SetStateAction<Request[]>>;
  fetchRequests: ({
    force,
    showLoading,
    refresh
  }: {
    force?: boolean;
    showLoading?: boolean;
    refresh?: boolean;
  }) => Promise<void>;
  requestsByStatus: Record<RequestStatus, Request[]>;
  filteredRequests: Request[];
  pendingRequestsCount: number;
  selectedStatus: RequestStatus;
  setSelectedStatus: React.Dispatch<React.SetStateAction<RequestStatus>>;
  onUpdateStatus: (request: Request, status: RequestStatus) => void;
  onUpdateAttendanceStatus: (
    request: Request,
    attendanceStatus: string
  ) => void;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchedRequests: Request[];
}

const RequestsContext = createContext<RequestsContextProps | undefined>(
  undefined
);

interface RequestsProviderProps {
  children: ReactNode;
}

export const RequestsProvider: React.FC<RequestsProviderProps> = ({
  children
}) => {
  const displayToast = useToast();
  const { selectedDentist: dentist } = useDentist();

  const [selectedStatus, setSelectedStatus] = useState<RequestStatus>(
    REQUEST_STATUS_OPTIONS.PENDING
  );
  const [requests, setRequests] = useState<Request[]>([]);
  const [lastFetchedAt, setLastFetchedAt] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [channel, setChannel] = useState<RealtimeChannel | null>(null);

  const previousDentistIdRef = useRef<string | null>(null);

  const fetchRequests = useCallback(
    async ({
      force = false,
      showLoading = true,
      refresh = false
    }: { force?: boolean; showLoading?: boolean; refresh?: boolean } = {}) => {
      if (!dentist?.id) return;

      if (dentist.id === previousDentistIdRef.current && !force) {
        setLoaded(true);
        return;
      }

      previousDentistIdRef.current = dentist.id;

      try {
        if (showLoading) setLoading(true);
        setLoaded(false);
        const today = moment().format('YYYY-MM-DD');

        // Fetch all requests at once when refreshing
        if (refresh) {
          const requests = await getRequestsForDashboard({
            dentistId: dentist.id
          });
          setRequests(requests);
          setLastFetchedAt(moment().unix());
          setLoaded(true);
          return;
        }

        // Fetch requests sequentially when loading for the first time
        // 1. Instantly get appointments for today
        const appointmentsForToday = await getAppointmentsForDay(
          dentist.id,
          today
        );
        setRequests(appointmentsForToday);
        setLastFetchedAt(moment().unix());
        setLoaded(true);
        if (showLoading) {
          setTimeout(() => {
            setLoading(false);
          }, FETCH_REQUESTS_TIMEOUT_IN_MS);
        }

        // 2. Get all future requests
        const futureRequests = await getRequestsForDashboard({
          dentistId: dentist.id,
          startDate: today
        });
        const presentToFutureRequests = [
          ...new Map(
            [...appointmentsForToday, ...futureRequests].map((request) => [
              request.id,
              request
            ])
          ).values()
        ];
        setRequests(presentToFutureRequests);
        setLastFetchedAt(moment().unix());

        // 3. Get all past requests
        const pastRequests = await getRequestsForDashboard({
          dentistId: dentist.id,
          endDate: today
        });
        const allRequests = [
          ...new Map(
            [...presentToFutureRequests, ...pastRequests].map((request) => [
              request.id,
              request
            ])
          ).values()
        ];
        setRequests(allRequests);
        setLastFetchedAt(moment().unix());
      } catch {
        displayToast({
          message: 'Please check your internet connection and try again.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      } finally {
        if (showLoading) {
          setTimeout(() => {
            setLoading(false);
          }, FETCH_REQUESTS_TIMEOUT_IN_MS);
        }
      }
    },
    [dentist?.id]
  );

  const stream = useCallback(
    async (refresh = false) => {
      if (channel) supabase.removeChannel(channel);

      if (!dentist?.id) {
        setChannel(null);
        return;
      }

      try {
        await fetchRequests({ force: true, refresh });
        const channelName = `requests${dentist.id?.replace(
          /-/g,
          ''
        )}${moment().unix()}`;
        const newChannel = supabase
          .channel(channelName)
          .on(
            'postgres_changes',
            {
              event: 'INSERT',
              schema: 'public',
              table: 'requests',
              filter: `dentist_id=eq.${dentist.id}`
            },
            async (payload) => {
              const newRequest = payload.new;
              const patient = await getPatient(newRequest.patient_id);
              setRequests((prevRequests) => [
                ...prevRequests.filter(
                  (request) => request.id !== newRequest.id
                ),
                { ...newRequest, patient: patient as Patient } as Request
              ]);
            }
          )
          .subscribe();

        setChannel(newChannel);
      } catch {
        displayToast({
          message: 'Please check your internet connection.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    },
    [dentist?.id, fetchRequests]
  );

  useEffect(() => {
    if (!channel) stream();

    App.addListener('pause', () => {
      if (channel) {
        supabase.removeChannel(channel);
        setChannel(null);
      }
    });

    App.addListener('resume', () => {
      if (!channel) stream(true);
    });

    return () => {
      App.removeAllListeners();
      if (channel) {
        supabase.removeChannel(channel);
        setChannel(null);
      }
    };
  }, [channel, stream]);

  const onUpdateStatus = useCallback(
    (request: Request, status: RequestStatus) => {
      setRequests((prev) =>
        prev.map((r) => (r.id === request.id ? { ...r, status } : r))
      );
    },
    []
  );

  const onUpdateAttendanceStatus = useCallback(
    (request: Request, attendanceStatus: string) => {
      setRequests((prev) =>
        prev.map((r) =>
          r.id === request.id
            ? { ...r, attendance_status: attendanceStatus }
            : r
        )
      );
    },
    []
  );

  const requestsByStatus = useMemo(() => {
    const sortedRequests = [...requests].sort((a, b) =>
      moment(a.date).diff(moment(b.date))
    );

    return sortedRequests.reduce(
      (acc, request) => {
        if (!acc[request.status]) {
          acc[request.status] = [];
        }
        acc[request.status].push(request);
        return acc;
      },
      {} as Record<RequestStatus, Request[]>
    );
  }, [requests]);

  const filteredRequests = useMemo(() => {
    if (Object.keys(requestsByStatus).length === 0) return [];

    const expirationTime = moment().add(
      REQUEST_EXPIRATION_BUFFER_IN_MINUTES,
      'minutes'
    );

    return (
      requestsByStatus[selectedStatus]?.filter((request: Request) => {
        if (selectedStatus === REQUEST_STATUS_OPTIONS.PENDING) {
          const requestDateTime = moment(
            `${request.date} ${request.start_time}`,
            'YYYY-MM-DD HH:mm'
          );
          return !requestDateTime.isBefore(expirationTime);
        }
        return true;
      }) || []
    );
  }, [lastFetchedAt, selectedStatus, requestsByStatus]);

  const formattedRequestsWithSearch = useMemo(() => {
    return requests.map((request) => ({
      ...request,
      searchString: [
        moment(request.date).format('MMMM D, YYYY'),
        moment(request.start_time, 'HH:mm').format('h:mm A'),
        moment(request.end_time, 'HH:mm').format('h:mm A'),
        `${request.patient?.first_name} ${request.patient?.last_name}`,
        `0${request.patient?.mobile_number}`
      ]
        .join('')
        .toLowerCase()
        .replace(/\s+/g, '')
    }));
  }, [requests]);

  const searchedRequests = useMemo(() => {
    if (searchQuery === '') return filteredRequests;
    const normalizedQuery = searchQuery.toLowerCase().replace(/\s+/g, '');
    return filteredRequests.filter((request) => {
      const formatted = formattedRequestsWithSearch.find(
        (r) => r.id === request.id
      );
      return formatted?.searchString.includes(normalizedQuery);
    });
  }, [searchQuery, filteredRequests, formattedRequestsWithSearch]);

  const pendingRequestsCount = useMemo(() => {
    if (Object.keys(requestsByStatus).length === 0) return 0;

    const currentDateAndTime = moment();
    return (
      requestsByStatus[REQUEST_STATUS_OPTIONS.PENDING]?.filter(
        (request: Request) => {
          const requestDate = moment(request.date);
          const requestStartTime = moment(
            `${request.date} ${request.start_time}`,
            'YYYY-MM-DD HH:mm'
          );

          return !(
            requestDate.isBefore(currentDateAndTime, 'day') ||
            (requestDate.isSame(currentDateAndTime, 'day') &&
              currentDateAndTime.add(1.5, 'hours').isAfter(requestStartTime))
          );
        }
      )?.length || 0
    );
  }, [requestsByStatus]);

  useEffect(() => {
    setLoaded(false);
  }, [dentist?.id]);

  useEffect(() => {
    return () => {
      setRequests([]);
      setSearchQuery('');
      setLoading(true);
      setLoaded(false);
    };
  }, []);

  return (
    <RequestsContext.Provider
      value={{
        loading,
        loaded,
        requests,
        setRequests,
        fetchRequests,
        requestsByStatus,
        filteredRequests,
        pendingRequestsCount,
        selectedStatus,
        setSelectedStatus,
        onUpdateStatus,
        onUpdateAttendanceStatus,
        searchQuery,
        setSearchQuery,
        searchedRequests
      }}
    >
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequests = () => {
  const context = useContext(RequestsContext);
  if (context === undefined) {
    throw new Error('useRequests must be used within a RequestsProvider');
  }
  return context;
};
