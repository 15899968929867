import React, {
  KeyboardEventHandler,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useDentist } from '@/contexts/DentistContext';
import { useRequests } from '@/contexts/RequestsContext';
import { useTracker } from '@/contexts/TrackerContext';
import { Patient, createPatient } from '@/data/Patients';
import { Request, createRequest } from '@/data/Requests';
import { getDefaultServices, getServicesByDentistId } from '@/data/Services';
import { useInputFocus } from '@/hooks/useInputFocus';
import useToast from '@/hooks/useToast';
import {
  PURPOSE_JOIN_SEPARATOR,
  REQUEST_STATUS_OPTIONS,
  SELECT_MODAL_OTHERS
} from '@/utils/constants';
import { formatName } from '@/utils/helpers/formatName';
import { Gender } from '@/utils/types';
import moment from 'moment';

interface ManualBookingContextProps {
  date: string | null;
  setDate: React.Dispatch<React.SetStateAction<string | null>>;
  startTime: string | null;
  setStartTime: React.Dispatch<React.SetStateAction<string | null>>;
  endTime: string | null;
  setEndTime: React.Dispatch<React.SetStateAction<string | null>>;
  purpose: string | null;
  setPurpose: React.Dispatch<React.SetStateAction<string | null>>;
  othersPurpose: string | null;
  setOthersPurpose: React.Dispatch<React.SetStateAction<string | null>>;
  firstName: string | null;
  setFirstName: React.Dispatch<React.SetStateAction<string | null>>;
  lastName: string | null;
  setLastName: React.Dispatch<React.SetStateAction<string | null>>;
  mobileNumber: string | null;
  setMobileNumber: React.Dispatch<React.SetStateAction<string | null>>;
  gender: Gender | null;
  setGender: React.Dispatch<React.SetStateAction<Gender | null>>;
  birthday: string | null;
  setBirthday: React.Dispatch<React.SetStateAction<string | null>>;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  options: string[];
  setOptions: React.Dispatch<React.SetStateAction<string[]>>;
  onInput: (field: string, value: any) => void;
  submit: () => Promise<void>;
  reset: () => void;
  onKeyDown: KeyboardEventHandler<HTMLIonInputElement>;
  isSelected: (value: string) => boolean;
  isPreSelected: (value: string) => boolean;
  onSelect: (value: string, isSelected: boolean) => void;
  isSelectModalOpen: boolean;
  setIsSelectModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseSelectModal: () => void;
  conflictingRequest: Request | null;
  inputRef: React.RefObject<HTMLIonInputElement>;
  timeOptions: string[];
}

const ManualBookingContext = createContext<
  ManualBookingContextProps | undefined
>(undefined);

interface ManualBookingProviderProps {
  isOpen?: boolean;
  onClose?: () => void;
  selectedDate?: Date | string | null | undefined;
  children: React.ReactNode;
}

export const ManualBookingProvider: React.FC<ManualBookingProviderProps> = ({
  isOpen,
  onClose,
  selectedDate,
  children
}) => {
  const { selectedDentist: dentist, dentistHours } = useDentist();
  const { requests, setRequests } = useRequests();
  const { captureEvent } = useTracker();
  const displayToast = useToast();

  const [date, setDate] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [purpose, setPurpose] = useState<string | null>(null);
  const [othersPurpose, setOthersPurpose] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [mobileNumber, setMobileNumber] = useState<string | null>(null);
  const [gender, setGender] = useState<Gender | null>(null);
  const [birthday, setBirthday] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({
    date: false,
    startTime: false,
    endTime: false,
    purpose: false,
    othersPurpose: false,
    firstName: false,
    lastName: false,
    mobileNumber: false,
    gender: false,
    birthday: false
  });
  const [options, setOptions] = useState<string[]>([]);
  const [conflictingRequest, setConflictingRequest] = useState<Request | null>(
    null
  );
  const [lastSavedPurpose, setLastSavedPurpose] = useState<string | null>(null);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLIonInputElement>(null);

  useInputFocus(inputRef);

  const getTimeOptions = (start: string, end: string) => {
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    const calculatedTimeOptions = [];

    while (startTime.isBefore(endTime)) {
      calculatedTimeOptions.push(startTime.format('HH:mm'));
      startTime.add(30, 'minutes');
    }

    return calculatedTimeOptions;
  };

  const timeOptions = useMemo(() => {
    if (dentistHours.length > 0) {
      const dayOfWeek = moment(date).day();
      const hoursForDay = dentistHours.find((hours) => hours.day === dayOfWeek);
      if (hoursForDay && hoursForDay.start_time && hoursForDay.end_time) {
        return getTimeOptions(hoursForDay.start_time, hoursForDay.end_time);
      }
    }
    return Array.from({ length: 48 }, (_, i) => {
      const hours = String(Math.floor((i + 8) / 2) % 24).padStart(2, '0');
      const minutes = (i + 12) % 2 === 0 ? '00' : '30';
      return `${hours}:${minutes}`;
    });
  }, [dentistHours, date]);

  const onInput = (field: string, value: any) => {
    switch (field) {
      case 'date':
        setDate(value);
        break;
      case 'startTime':
        setStartTime(value);
        break;
      case 'endTime':
        setEndTime(value);
        break;
      case 'purpose':
        setPurpose(value);
        break;
      case 'othersPurpose':
        setOthersPurpose(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'mobileNumber':
        setMobileNumber(value);
        break;
      case 'gender':
        setGender(value);
        break;
      case 'birthday':
        setBirthday(value);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
  };

  const reset = useCallback(() => {
    setDate(null);
    setStartTime(null);
    setEndTime(null);
    setPurpose(null);
    setOthersPurpose(null);
    setFirstName(null);
    setLastName(null);
    setMobileNumber(null);
    setGender(null);
    setBirthday(null);
    setErrors({
      date: false,
      startTime: false,
      endTime: false,
      purpose: false,
      othersPurpose: false,
      firstName: false,
      lastName: false,
      mobileNumber: false,
      gender: false,
      birthday: false
    });
  }, []);

  const submit = useCallback(async () => {
    try {
      const newErrors = {
        date: !date,
        startTime: !startTime,
        endTime: !endTime,
        purpose: !purpose,
        othersPurpose: purpose === SELECT_MODAL_OTHERS && !othersPurpose,
        firstName: !formatName(firstName),
        lastName: !formatName(lastName),
        mobileNumber: !mobileNumber || mobileNumber.length !== 10,
        gender: !gender,
        birthday: !birthday
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some((error) => error)) {
        displayToast({
          message: 'Please fill in all required fields.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'createRequestButton'
        });
        return;
      }

      if (!dentist?.id) {
        setErrors((prevErrors) => ({ ...prevErrors, dentist: true }));
        displayToast({
          message: 'Please select a dentist.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'createRequestButton'
        });
        return;
      }

      setLoading(true);

      const newPatient: Patient = {
        first_name: formatName(firstName),
        last_name: formatName(lastName),
        mobile_number: mobileNumber,
        gender: gender as Gender | null,
        birthday: birthday
      };

      const createdPatient = await createPatient(newPatient);

      if (!createdPatient) {
        setLoading(false);
        displayToast({
          message:
            'Failed to create patient. Please check your internet connection and try again.',
          duration: 3000,
          position: 'bottom'
        });
        return;
      }

      const newRequest: Request = {
        date: date || null,
        start_time: startTime || null,
        end_time: endTime || null,
        purpose:
          purpose === SELECT_MODAL_OTHERS
            ? `${purpose}: ${othersPurpose}`
            : purpose,
        status: REQUEST_STATUS_OPTIONS.APPROVED,
        dentist_id: dentist.id,
        patient_id: createdPatient.id!
      };

      const createdRequest = await createRequest(newRequest);

      if (createdRequest) {
        setRequests([
          ...requests,
          { ...createdRequest, patient: createdPatient }
        ]);
        captureEvent({
          event: 'manual booking created',
          properties: { request_id: createdRequest.id! }
        });
        setLoading(false);
        reset();
        onClose?.();
      }
    } catch {
      setLoading(false);
      displayToast({
        message:
          'Failed to create appointment. Please check your internet connection and try again.',
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'createRequestButton'
      });
    }
  }, [
    date,
    startTime,
    endTime,
    purpose,
    othersPurpose,
    firstName,
    lastName,
    mobileNumber,
    gender,
    birthday,
    dentist,
    requests,
    setRequests,
    displayToast,
    reset
  ]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLIonInputElement>) => {
      if (event.key === 'Enter') {
        submit();
      }
    },
    [submit]
  );

  useEffect(() => {
    return () => {
      setDate(null);
      setStartTime(null);
      setEndTime(null);
      setPurpose(null);
      setOthersPurpose(null);
      setFirstName(null);
      setLastName(null);
      setMobileNumber(null);
      setGender(null);
      setBirthday(null);
      setOptions([]);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      if (dentist?.id) {
        try {
          const services = await getServicesByDentistId(dentist.id);
          if (services.length > 0) {
            setOptions(services.map((service) => service.name));
          } else {
            const defaultServices = await getDefaultServices();
            setOptions(defaultServices.map((service) => service.name));
          }
        } catch {
          displayToast({
            message: `Please check your internet connection.`,
            duration: 3000,
            position: 'bottom'
          });
        }
      }
    };

    if (isOpen) fetchServices();
  }, [dentist?.id, isOpen]);

  useEffect(() => {
    const checkConflictingRequest = () => {
      const slotStartTime = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm');
      const slotEndTime = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm');

      const conflict = requests
        .filter(
          (request) =>
            moment(date).isSame(moment(request.date, 'YYYY-MM-DD'), 'day') &&
            request.status === REQUEST_STATUS_OPTIONS.APPROVED
        )
        .find((request) => {
          const requestStartTime = moment(
            `${request.date} ${request.start_time}`,
            'YYYY-MM-DD HH:mm'
          );
          const requestEndTime = moment(
            `${request.date} ${request.end_time}`,
            'YYYY-MM-DD HH:mm'
          );

          return (
            slotStartTime.isBetween(
              requestStartTime,
              requestEndTime,
              null,
              '[)'
            ) ||
            slotEndTime.isBetween(
              requestStartTime,
              requestEndTime,
              null,
              '(]'
            ) ||
            requestStartTime.isBetween(
              slotStartTime,
              slotEndTime,
              null,
              '[)'
            ) ||
            requestEndTime.isBetween(slotStartTime, slotEndTime, null, '(]')
          );
        });

      setConflictingRequest(conflict ?? null);
    };

    checkConflictingRequest();
  }, [date, startTime, endTime, requests.length]);

  const isSelected = useCallback(
    (value: string) =>
      purpose?.split(PURPOSE_JOIN_SEPARATOR).includes(value) || false,
    [purpose]
  );

  const isPreSelected = useCallback(
    (value: string) =>
      lastSavedPurpose?.split(PURPOSE_JOIN_SEPARATOR).includes(value) || false,
    [lastSavedPurpose]
  );

  const onSelect = useCallback(
    (value: string, isSelected: boolean) => {
      if (purpose) {
        if (isSelected) {
          setPurpose(
            purpose
              .split(',')
              .map((item) => item.trim())
              .filter((item) => item !== value)
              .join(PURPOSE_JOIN_SEPARATOR)
          );
        } else {
          setPurpose(purpose + PURPOSE_JOIN_SEPARATOR + value);
        }
      } else {
        setPurpose(value);
      }
    },
    [purpose]
  );

  const onCloseSelectModal = useCallback(() => {
    setIsSelectModalOpen(false);
    setLastSavedPurpose(purpose);
  }, [purpose]);

  return (
    <ManualBookingContext.Provider
      value={{
        date,
        setDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        purpose,
        setPurpose,
        othersPurpose,
        setOthersPurpose,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        mobileNumber,
        setMobileNumber,
        gender,
        setGender,
        birthday,
        setBirthday,
        errors,
        setErrors,
        loading,
        setLoading,
        options,
        setOptions,
        onInput,
        submit,
        reset,
        onKeyDown,
        isSelected,
        isPreSelected,
        onSelect,
        onCloseSelectModal,
        conflictingRequest,
        inputRef,
        isSelectModalOpen,
        setIsSelectModalOpen,
        timeOptions
      }}
    >
      {children}
    </ManualBookingContext.Provider>
  );
};

export const useManualBooking = () => {
  const context = useContext(ManualBookingContext);
  if (context === undefined) {
    throw new Error(
      'useManualBooking must be used within a ManualBookingProvider'
    );
  }
  return context;
};
