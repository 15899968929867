import { NavContext } from '@ionic/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useClinic } from './ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useTracker } from '@/contexts/TrackerContext';
import { DentistHour, createDentistHour } from '@/data/DentistHours';
import { Dentist, createDentist, deleteDentist } from '@/data/Dentists';
import { Service, createService } from '@/data/Services';
import useToast from '@/hooks/useToast';
import { ADD_DENTIST_STEPS } from '@/utils/constants';
import { ROUTES } from '@/utils/routes';

interface AddDentistContextProps {
  loading: boolean;
  currentStep: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  goBack: () => void;
  firstName: string | null;
  setFirstName: React.Dispatch<React.SetStateAction<string | null>>;
  lastName: string | null;
  setLastName: React.Dispatch<React.SetStateAction<string | null>>;
  onAddDentist: (hours: DentistHour[]) => Promise<void>;
  onReset: () => Promise<void>;
  mobileNumber: string | null;
  setMobileNumber: React.Dispatch<React.SetStateAction<string | null>>;
  services: Service[];
  setServices: React.Dispatch<React.SetStateAction<Service[]>>;
  onCreateServices: (services: Service[]) => Promise<void>;
  onSkip: () => void;
}

const AddDentistContext = createContext<AddDentistContextProps | undefined>(
  undefined
);

interface AddDentistProviderProps {
  children: React.ReactNode;
}

export const AddDentistProvider: React.FC<AddDentistProviderProps> = ({
  children
}) => {
  const { navigate, goBack } = useContext(NavContext);
  const { selectedClinic: clinic } = useClinic();
  const { dentists, selectDentist, setDentists } = useDentist();
  const { captureEvent } = useTracker();
  const displayToast = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [mobileNumber, setMobileNumber] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<string>(
    ADD_DENTIST_STEPS.NAME
  );
  const [dentist, setDentist] = useState<Dentist | null>(null);
  const [services, setServices] = useState<Service[]>([]);

  const onCreateDentist = useCallback(async () => {
    if (firstName && lastName && firstName.length > 1 && lastName.length > 1) {
      try {
        setLoading(true);
        const sanitizedFirstName = firstName?.replace(/^(dr\.|Dr\.)\s*/i, '');
        const newDentist = await createDentist({
          first_name: sanitizedFirstName,
          last_name: lastName,
          clinic_id: clinic?.id,
          mobile_number: mobileNumber
        } as Dentist);

        if (newDentist) {
          setDentists([...dentists, newDentist]);
          setLoading(false);
          return newDentist;
        }

        setLoading(false);
        displayToast({
          message: 'Error creating dentist. Please contact support.',
          duration: 5000,
          position: 'bottom',
          positionAnchor: 'nextButton'
        });
        return null;
      } catch {
        setLoading(false);
        displayToast({
          message: 'Error creating dentist. Please contact support.',
          duration: 5000,
          position: 'bottom',
          positionAnchor: 'nextButton'
        });
        return null;
      }
    } else {
      displayToast({
        message: 'Please enter a valid first and last name.',
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
      return null;
    }
  }, [firstName, lastName, clinic?.id, dentists, setDentists]);

  const onCreateDentistHours = useCallback(
    async (hours: DentistHour[], dentistId: string) => {
      try {
        setLoading(true);
        const dentistHours = await Promise.all(
          hours.map((hour) =>
            createDentistHour({ ...hour, dentist_id: dentistId } as DentistHour)
          )
        );
        const dentistHoursWithErrors = dentistHours.filter(
          (hour) => hour === null
        );
        if (dentistHoursWithErrors.length > 0) {
          throw new Error('Error creating some dentist hours.');
        }
        setLoading(false);
        return dentistHours;
      } catch {
        setLoading(false);
        displayToast({
          message: 'Error creating some dentist hours. Please contact support.',
          duration: 5000,
          position: 'bottom',
          positionAnchor: 'nextButton'
        });
        return null;
      }
    },
    [displayToast]
  );

  const onReset = useCallback(async () => {
    setLoading(true);
    selectDentist(null);
    setLoading(false);
    setCurrentStep(ADD_DENTIST_STEPS.NAME);
  }, [selectDentist]);

  const onAddDentist = useCallback(
    async (hours: DentistHour[]) => {
      setLoading(true);
      const newDentist = await onCreateDentist();
      if (newDentist?.id) {
        const dentistHours = await onCreateDentistHours(hours, newDentist.id);
        if (dentistHours) {
          setDentist(newDentist);
          captureEvent({
            event: 'dentist added',
            properties: { dentist_id: newDentist.id }
          });
          setCurrentStep(ADD_DENTIST_STEPS.SERVICES);
          setLoading(false);
        } else {
          await deleteDentist(newDentist.id);
          displayToast({
            message:
              'Error setting dentist working days and hours. Please contact support.',
            duration: 5000,
            position: 'bottom',
            positionAnchor: 'nextButton'
          });
          setLoading(false);
          onReset();
        }
      } else {
        displayToast({
          message: 'Error creating dentist. Please contact support.',
          duration: 5000,
          position: 'bottom',
          positionAnchor: 'nextButton'
        });
        setLoading(false);
        onReset();
      }
    },
    [
      onCreateDentist,
      onCreateDentistHours,
      selectDentist,
      navigate,
      displayToast,
      onReset
    ]
  );

  const onSkip = useCallback(() => {
    displayToast({
      message:
        'Skipped adding services, you can add them later. Welcome to your dashboard!',
      duration: 3000,
      position: 'bottom',
      positionAnchor: 'tabBar'
    });
    selectDentist(dentist);
    navigate(ROUTES.CALENDAR);
  }, [dentist, navigate]);

  const onCreateServices = useCallback(
    async (services: Service[]) => {
      if (!dentist?.id) {
        displayToast({
          message: 'Dentist is not set. Please contact support.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'nextButton'
        });
        return;
      }

      try {
        setLoading(true);
        const dentistServices = await Promise.all(
          services.map((service) =>
            createService({ ...service, dentist_id: dentist.id } as Service)
          )
        );

        const hasErrors = dentistServices.some((service) => service === null);

        if (hasErrors) {
          throw new Error('Error creating dentist services.');
        }

        displayToast({
          message: 'Dentist added successfully! Welcome to your dashboard.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });

        selectDentist(dentist);
        setLoading(false);
        navigate(ROUTES.CALENDAR);
      } catch {
        setLoading(false);
        displayToast({
          message: 'Error creating services. Please add them later.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'nextButton'
        });
        onSkip();
      }
    },
    [dentist?.id, navigate, selectDentist, onSkip]
  );

  useEffect(() => {
    return () => {
      setFirstName(null);
      setLastName(null);
      setMobileNumber(null);
      setCurrentStep(ADD_DENTIST_STEPS.NAME);
      setDentist(null);
      setServices([]);
      setLoading(false);
    };
  }, []);

  return (
    <AddDentistContext.Provider
      value={{
        loading,
        currentStep,
        setCurrentStep,
        goBack,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        mobileNumber,
        setMobileNumber,
        onAddDentist,
        onReset,
        services,
        setServices,
        onCreateServices,
        onSkip
      }}
    >
      {children}
    </AddDentistContext.Provider>
  );
};

export const useAddDentist = () => {
  const context = useContext(AddDentistContext);
  if (context === undefined) {
    throw new Error('useAddDentist must be used within a AddDentistProvider');
  }
  return context;
};
