import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonLoading,
  IonPage,
  IonRouterLink,
  IonText,
  NavContext
} from '@ionic/react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Browser } from '@capacitor/browser';
import { Dialog } from '@capacitor/dialog';

import Avatar from '@/components/common/avatar/Avatar';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import Tips from '@/components/common/tips/Tips';
import DashboardHeader from '@/components/dashboard/common/dashboardHeader/DashboardHeader';
import UpdateAccess from '@/components/dashboard/profile/updateAccess/UpdateAccess';
import UpdatePatientVerification from '@/components/dashboard/profile/updatePatientVerification/UpdatePatientVerification';
import UpdateServices from '@/components/dashboard/profile/updateServices/UpdateServices';
import UpdateSpecializations from '@/components/dashboard/profile/updateSpecializations/UpdateSpecializations';
import UpdateWorkingHours from '@/components/dashboard/profile/updateWorkingHours/UpdateWorkingHours';
import WeeklyReports from '@/components/dashboard/profile/weeklyReports/WeeklyReports';
import { useAuth } from '@/contexts/AuthenticationContext';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useShare } from '@/contexts/ShareContext';
import { updateDentistShowProfileTips } from '@/data/Dentists';
import useToast from '@/hooks/useToast';
import { HELP_URL } from '@/utils/constants';
import { encodeShareCode } from '@/utils/helpers/encodeShareCode';
import { formatName } from '@/utils/helpers/formatName';
import { getInitials } from '@/utils/helpers/getInitials';
import { ROUTES } from '@/utils/routes';
import { ProfileSettingItem } from '@/utils/types';
import {
  analyticsOutline,
  helpCircleOutline,
  keyOutline,
  logOutOutline,
  medkitOutline,
  schoolOutline,
  shieldCheckmarkOutline,
  swapHorizontalOutline,
  timeOutline,
  trashOutline
} from 'ionicons/icons';

import './Profile.css';

const Profile: React.FC = () => {
  const {
    selectDentist,
    selectedDentist: dentist,
    setSelectedDentist
  } = useDentist();
  const { isClinic } = useClinic();
  const {
    share,
    onUpdateShareCode,
    onShare,
    loading: updatingShareCode
  } = useShare();
  const { signOut } = useAuth();
  const { navigate } = useContext(NavContext);
  const displayToast = useToast();

  const [shareCode, setShareCode] = useState<string>(share?.code || '');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const [isWeeklyReportsModalOpen, setIsWeeklyReportsModalOpen] =
    useState<boolean>(false);
  const [isWorkingHoursModalOpen, setIsWorkingHoursModalOpen] =
    useState<boolean>(false);
  const [isServicesModalOpen, setIsServicesModalOpen] =
    useState<boolean>(false);
  const [isAccessModalOpen, setIsAccessModalOpen] = useState<boolean>(false);
  const [isPatientVerificationModalOpen, setIsPatientVerificationModalOpen] =
    useState<boolean>(false);
  const [isSpecializationsModalOpen, setIsSpecializationsModalOpen] =
    useState<boolean>(false);
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);

  const page = useRef<HTMLElement | undefined>(undefined);

  useEffect(() => {
    setPresentingElement(page.current);
  }, [page.current]);

  useEffect(() => {
    if (share) setShareCode(share.code);

    return () => {
      setShareCode('');
    };
  }, [share]);

  const onSwitchDentist = useCallback(async () => {
    await selectDentist(null);
    navigate(ROUTES.SELECT_DENTIST);
  }, [selectDentist, navigate]);

  const onSignOut = useCallback(async () => {
    await selectDentist(null);
    signOut();
  }, [selectDentist, signOut]);

  const onShareButtonClick = useCallback(async () => {
    if (isInputFocused || shareCode !== share?.code) {
      if (!shareCode) {
        displayToast({
          message: 'Custom link cannot be empty.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      } else {
        await onUpdateShareCode(shareCode);
        setShareCode(encodeShareCode(shareCode));
        setIsInputFocused(false);
        displayToast({
          message: 'Your custom link has been updated.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    } else {
      onShare();
    }
  }, [isInputFocused, shareCode, share, onUpdateShareCode, onShare]);

  const onDeleteAccount = async () => {
    await Dialog.alert({
      title: 'Delete Account',
      message:
        'To delete your account, please contact our support team at hello@odonto.ph.'
    });
  };

  const onHelp = async () => {
    await Browser.open({ url: HELP_URL });
  };

  const profileSettingItems: ProfileSettingItem[] = [
    ...(isClinic
      ? [
          {
            icon: keyOutline,
            text: 'Manage Access',
            onClick: () => setIsAccessModalOpen(true)
          }
        ]
      : []),
    {
      icon: medkitOutline,
      text: 'Manage Services',
      onClick: () => setIsServicesModalOpen(true)
    },
    {
      icon: timeOutline,
      text: 'Manage Working Hours',
      onClick: () => setIsWorkingHoursModalOpen(true)
    },
    {
      icon: schoolOutline,
      text: 'Manage Specializations',
      onClick: () => setIsSpecializationsModalOpen(true)
    },
    {
      icon: shieldCheckmarkOutline,
      text: 'Patient Verification',
      onClick: () => setIsPatientVerificationModalOpen(true)
    },
    {
      icon: swapHorizontalOutline,
      text: `Switch ${isClinic ? 'Dentist' : 'Clinic'}`,
      onClick: onSwitchDentist
    },
    {
      icon: helpCircleOutline,
      text: 'Help',
      onClick: onHelp
    },
    {
      icon: logOutOutline,
      text: 'Sign Out',
      onClick: onSignOut
    }
  ];

  const tips = [
    <>
      To request a personalized QR code, just{' '}
      <IonRouterLink onClick={onHelp}>send us a message</IonRouterLink>.
    </>,
    'Boost bookings by sharing your link on your Facebook page.',
    'Custom booking links are available on a first-come, first-served basis. Reserve yours now!',
    'Mention your link on auto-replies so patients can book immediately.'
  ];

  const onHideTips = async () => {
    if (dentist?.id) {
      await updateDentistShowProfileTips(dentist.id, false);
      setSelectedDentist({ ...dentist, show_profile_tips: false });
    }
  };

  return (
    <IonPage id="profile" ref={page}>
      <IonContent>
        <Padding>
          <div id="fixedHeader">
            <DashboardHeader
              title="Profile"
              presentingElement={presentingElement}
            />
            <Spacer top={4} bottom={16} />
          </div>
          <Spacer top={114} bottom={62}>
            <div id="dentist">
              <div id="dentistAccent"></div>
              <div id="dentistContent">
                <Avatar
                  color="primary"
                  initials={getInitials(dentist)}
                  className="dentistAvatar"
                />
                <Spacer top={12} />
                <p className="dentistName">
                  Dr. {formatName(dentist?.first_name)}{' '}
                  <IonText color="primary">
                    {formatName(dentist?.last_name)}
                  </IonText>
                </p>
                {dentist?.clinic?.name && (
                  <p className="clinicName">{dentist?.clinic?.name}</p>
                )}
              </div>
            </div>
          </Spacer>
          <div id="bookingLink" className="section">
            <h3 id="bookingLinkTitle" className="sectionTitle">
              Booking Link
            </h3>
            <div className="shareLink">
              <IonInput
                value={shareCode}
                placeholder="Set your custom link"
                onFocus={() => {
                  setIsInputFocused(true);
                  setTimeout(() => {}, 1000);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setIsInputFocused(false);
                  }, 1000);
                }}
                onIonInput={(e: CustomEvent) => setShareCode(e.detail.value!)}
              >
                <div slot="start" className="shareLinkPrefix">
                  odonto.ph/s/
                </div>
              </IonInput>
              <IonButton
                onClick={onShareButtonClick}
                expand="block"
                fill="solid"
                size="small"
                color="primary"
                strong={true}
              >
                {isInputFocused || shareCode !== share?.code
                  ? share
                    ? 'Update'
                    : 'Create'
                  : 'Share'}
              </IonButton>
            </div>
            {dentist?.show_profile_tips && (
              <Tips content={tips} onHide={onHideTips} />
            )}
          </div>
          <Spacer top={42} />
          <div id="analytics" className="section">
            <h3 id="analyticsTitle" className="sectionTitle">
              Analytics
            </h3>
            <Spacer top={8} bottom={42}>
              <div id="analyticsItems">
                <div
                  className="analyticsItem"
                  onClick={() => setIsWeeklyReportsModalOpen(true)}
                >
                  <IonIcon icon={analyticsOutline} />
                  <p>View Weekly Reports</p>
                </div>
              </div>
            </Spacer>
          </div>
          <div id="profileSettings" className="section">
            <h3 id="profileSettingsTitle" className="sectionTitle">
              Account Settings
            </h3>
            <Spacer top={8} bottom={42}>
              <div id="profileSettingItems">
                {profileSettingItems.map((item, index) => (
                  <div
                    key={index}
                    className="profileSettingItem"
                    onClick={item.onClick}
                  >
                    <IonIcon icon={item.icon} />
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
            </Spacer>
          </div>
          <div id="dangerZoneSettings" className="section">
            <h3 id="dangerZoneSettingsTitle" className="sectionTitle">
              Danger Zone
            </h3>
            <Spacer top={8} bottom={42}>
              <div id="dangerZoneSettingItems">
                <div
                  className="profileSettingItem"
                  onClick={() => onDeleteAccount()}
                >
                  <IonIcon icon={trashOutline} className="danger" />
                  <p>Delete Account</p>
                </div>
              </div>
            </Spacer>
          </div>

          <WeeklyReports
            isOpen={isWeeklyReportsModalOpen}
            onClose={() => setIsWeeklyReportsModalOpen(false)}
            presentingElement={presentingElement}
            dentistId={dentist?.id}
          />
          <UpdateWorkingHours
            isOpen={isWorkingHoursModalOpen}
            onClose={() => setIsWorkingHoursModalOpen(false)}
            presentingElement={presentingElement}
          />
          <UpdateServices
            isOpen={isServicesModalOpen}
            onClose={() => setIsServicesModalOpen(false)}
            presentingElement={presentingElement}
          />
          {isClinic && (
            <UpdateAccess
              isOpen={isAccessModalOpen}
              onClose={() => setIsAccessModalOpen(false)}
              presentingElement={presentingElement}
            />
          )}
          <UpdatePatientVerification
            isOpen={isPatientVerificationModalOpen}
            onClose={() => setIsPatientVerificationModalOpen(false)}
            presentingElement={presentingElement}
          />
          <UpdateSpecializations
            isOpen={isSpecializationsModalOpen}
            onClose={() => setIsSpecializationsModalOpen(false)}
            presentingElement={presentingElement}
          />

          <IonLoading
            isOpen={updatingShareCode}
            message="Loading..."
            spinner="crescent"
          />
        </Padding>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
