import {
  IonBadge,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  IonSearchbar,
  NavContext
} from '@ionic/react';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import React from 'react';

import Empty from '@/components/common/empty/Empty';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import DashboardHeader from '@/components/dashboard/common/dashboardHeader/DashboardHeader';
import SharePrompt from '@/components/dashboard/common/sharePrompt/SharePrompt';
import RequestItem from '@/components/dashboard/requests/requestItem/RequestItem';
import RequestPlaceholder from '@/components/dashboard/requests/requestPlaceholer/RequestPlaceholder';
import StatusOptions from '@/components/dashboard/requests/statusOptions/StatusOptions';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useNotifications } from '@/contexts/NotificationsContext';
import { useRequests } from '@/contexts/RequestsContext';
import { Request } from '@/data/Requests';
import {
  ENTRIES_PER_PAGE,
  OFF_DUTY_PATIENT_ID,
  REFRESH_TIMEOUT_IN_MS,
  REQUEST_STATUS_OPTIONS
} from '@/utils/constants';
import capitalize from '@/utils/helpers/capitalize';
import { debounce } from '@/utils/helpers/debounce';
import { ROUTES } from '@/utils/routes';
import { refreshOutline, swapHorizontalOutline } from 'ionicons/icons';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import './Requests.css';

interface RequestsProps {}

const Requests: React.FC<RequestsProps> = () => {
  const { isClinic } = useClinic();
  const { onSwitch, dentists } = useDentist();
  const { fetchNotifications } = useNotifications();
  const {
    loading,
    loaded,
    fetchRequests,
    selectedStatus,
    setSelectedStatus,
    pendingRequestsCount,
    filteredRequests,
    searchQuery,
    setSearchQuery,
    searchedRequests,
    onUpdateStatus
  } = useRequests();
  const { navigate } = useContext(NavContext);

  const { search } = useLocation();
  const queryFromUrl = new URLSearchParams(search).get('query');
  const statusFromUrl = new URLSearchParams(search).get('status');

  const [updating, setUpdating] = useState<boolean>(false);
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);
  const [page, setPage] = useState<number>(1);
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState<boolean>(false);

  const pageRef = useRef<HTMLElement | undefined>(undefined);
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const statusOptions = Object.values(REQUEST_STATUS_OPTIONS);

  const filteredAndSearchedRequests = useMemo(() => {
    const requests = searchQuery ? searchedRequests : filteredRequests;
    return requests.filter(
      (request) => request.patient_id !== OFF_DUTY_PATIENT_ID
    );
  }, [searchQuery, searchedRequests, filteredRequests]);

  const groupRequests = (requests: Request[]) => {
    return requests.reduce(
      (acc: Record<string, Request[]>, request: Request) => {
        const requestDate = moment(request.date).format('MMMM D, YYYY');
        if (!acc[requestDate]) {
          acc[requestDate] = [];
        }
        acc[requestDate].push(request);
        acc[requestDate].sort((a, b) =>
          moment(a.start_time, 'HH:mm').diff(moment(b.start_time, 'HH:mm'))
        );
        return acc;
      },
      {}
    );
  };

  const groupedRequests = useMemo(
    () => groupRequests(filteredAndSearchedRequests),
    [filteredAndSearchedRequests]
  );

  const debouncedSetSearchQuery = useCallback(
    debounce((value: string) => setSearchQuery(value), 324),
    []
  );

  const handleRefresh = useCallback(
    async (event: CustomEvent) => {
      setTimeout(async () => {
        await fetchRequests({ force: true, refresh: true });
        await fetchNotifications({ force: true });
        event.detail.complete();
      }, REFRESH_TIMEOUT_IN_MS);
    },
    [fetchRequests, fetchNotifications]
  );

  const loadMore = (ev: CustomEvent<void>) => {
    setTimeout(() => {
      setPage((prevPage) => prevPage + 1);
      (ev.target as HTMLIonInfiniteScrollElement).complete();
    }, 500);
  };

  useEffect(() => {
    setPresentingElement(pageRef.current);
  }, [pageRef.current]);

  useEffect(() => {
    if (queryFromUrl) {
      setSearchQuery(decodeURIComponent(queryFromUrl));
    }
  }, [queryFromUrl]);

  useEffect(() => {
    if (
      statusFromUrl &&
      Object.values(REQUEST_STATUS_OPTIONS).includes(statusFromUrl)
    ) {
      setSelectedStatus(statusFromUrl);
    }
  }, [statusFromUrl]);

  useEffect(() => {
    const entries =
      selectedStatus === REQUEST_STATUS_OPTIONS.PENDING
        ? Object.entries(groupedRequests)
        : Object.entries(groupedRequests).reverse();

    const paginatedEntries = entries.slice(0, page * ENTRIES_PER_PAGE);
    setIsInfiniteDisabled(paginatedEntries.length >= entries.length);
  }, [selectedStatus, searchQuery, groupedRequests, page]);

  useEffect(() => {
    if (
      searchQuery === null ||
      (searchQuery === '' && (queryFromUrl !== null || queryFromUrl !== ''))
    ) {
      navigate(`${ROUTES.REQUESTS}`);
    }
  }, [searchQuery]);

  useEffect(() => {
    setPage(1);
    setIsInfiniteDisabled(false);
    if (contentRef.current) {
      contentRef.current.scrollToTop(0);
    }
  }, [selectedStatus, searchQuery, contentRef.current]);

  const renderRequestItems = (requests: Request[]) => {
    if (loading || !loaded) {
      return Array.from({ length: 3 }).map((_, index) => (
        <RequestPlaceholder key={index} />
      ));
    }

    if (requests.length === 0) {
      return (
        <>
          <Empty
            message={
              searchQuery && searchQuery !== ''
                ? `No results found for "${searchQuery}" on ${capitalize(selectedStatus)}`
                : `No ${selectedStatus} requests found`
            }
            marginTop={72}
          />
          <SharePrompt />
          {dentists.length > 1 && (
            <Spacer top={-32} bottom={16}>
              <div className="switch">
                <IonRouterLink onClick={onSwitch}>
                  <IonIcon icon={swapHorizontalOutline} />
                  Switch {isClinic ? 'Dentist' : 'Clinic'}
                </IonRouterLink>
              </div>
            </Spacer>
          )}
        </>
      );
    }

    const entries =
      selectedStatus === REQUEST_STATUS_OPTIONS.PENDING
        ? Object.entries(groupedRequests)
        : Object.entries(groupedRequests).reverse();

    const paginatedEntries = entries.slice(0, page * ENTRIES_PER_PAGE);

    return paginatedEntries.map(([date, items]) => (
      <div key={date} className="requestDateGroup">
        <div className="requestDateGroupHeader">
          <h4 className="requestDateGroupLabel">{date.split(',')[0]}</h4>
          {moment(date, 'MMMM D, YYYY').isSame(moment(), 'day') && (
            <IonBadge color="primary" className="requestDateGroupBadge">
              Today
            </IonBadge>
          )}
          {moment(date, 'MMMM D, YYYY').isSame(
            moment().add(1, 'day'),
            'day'
          ) && (
            <IonBadge color="medium" className="requestDateGroupBadge">
              Tomorrow
            </IonBadge>
          )}
          {moment(date, 'MMMM D, YYYY').isBefore(moment(), 'day') && (
            <IonBadge color="medium" className="requestDateGroupBadge">
              Past
            </IonBadge>
          )}
        </div>
        {items.map((request: Request) => (
          <RequestItem
            key={request.id}
            request={request}
            onUpdateStatus={onUpdateStatus}
            setUpdating={setUpdating}
          />
        ))}
      </div>
    ));
  };

  return (
    <IonPage id="requests" ref={pageRef}>
      <IonContent ref={contentRef}>
        <IonRefresher
          id="requestsRefresher"
          slot="fixed"
          onIonRefresh={handleRefresh}
        >
          <IonRefresherContent
            pullingIcon={refreshOutline}
            refreshingSpinner="crescent"
          />
        </IonRefresher>

        <Padding>
          <div id="fixedHeader">
            <DashboardHeader
              title="Requests"
              presentingElement={presentingElement}
            />
            <IonSearchbar
              id="searchbar"
              placeholder="Search requests"
              value={searchQuery}
              onIonInput={(event: CustomEvent) =>
                debouncedSetSearchQuery(event.detail.value)
              }
              onIonClear={() => setSearchQuery('')}
            />
            <Spacer top={4} bottom={16}>
              <StatusOptions
                selectedStatus={selectedStatus}
                onStatusChange={setSelectedStatus}
                statusOptions={statusOptions}
                pendingRequestsCount={pendingRequestsCount}
              />
            </Spacer>
          </div>
          <Spacer top={184} bottom={24}>
            <div id="requestsList">
              {renderRequestItems(filteredAndSearchedRequests)}
            </div>
          </Spacer>
        </Padding>

        {filteredAndSearchedRequests.length > 0 &&
          filteredAndSearchedRequests.length > ENTRIES_PER_PAGE && (
            <IonInfiniteScroll
              onIonInfinite={loadMore}
              disabled={isInfiniteDisabled}
            >
              <IonInfiniteScrollContent
                loadingSpinner="crescent"
                loadingText="Loading more requests..."
              />
            </IonInfiniteScroll>
          )}

        <IonLoading isOpen={updating} message="Loading..." spinner="crescent" />
      </IonContent>
    </IonPage>
  );
};

export default Requests;
