import { IonIcon } from '@ionic/react';
import React from 'react';

import { useSubscription } from '@/contexts/SubscriptionContext';
import { PLAN_DESCRIPTIONS, PLAN_NAMES } from '@/utils/subscriptions';
import { checkmarkCircle, ellipseOutline } from 'ionicons/icons';

import './Plans.css';

const Plans: React.FC = () => {
  const { plans, selectedPlan, setSelectedPlan } = useSubscription();

  const copy = {
    badge: 'MOST POPULAR'
  };

  return (
    <div className="plans">
      {plans.map((plan, index) => (
        <div
          key={plan.identifier}
          className={`plan ${selectedPlan?.identifier === plan.identifier ? 'selected' : ''}`}
          onClick={() => setSelectedPlan(plan)}
        >
          {index === 1 && <p className="badge">{copy.badge}</p>}
          <div className="planHeader">
            <IonIcon
              className="icon"
              icon={
                selectedPlan?.identifier === plan.identifier
                  ? checkmarkCircle
                  : ellipseOutline
              }
              color={
                selectedPlan?.identifier === plan.identifier
                  ? 'primary'
                  : 'medium'
              }
            />
            <h1 className="title">{PLAN_NAMES[plan.identifier]}</h1>
          </div>
          <p className="description">{PLAN_DESCRIPTIONS[plan.identifier]}</p>
        </div>
      ))}
    </div>
  );
};

export default Plans;
