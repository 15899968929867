import {
  getRecord,
  getRecords,
  insertRecord,
  updateRecord,
} from "@/utils/helpers/data";
import { supabase } from "@/utils/supabase";

export interface DentistNotification {
  id?: string;
  message: string;
  dentist_id: string;
  read_at: string | null;
  created_at?: string;
  request_id?: string | null;
}

/**
 * Creates a new dentist notification record.
 * @param dentistNotification - The dentist notification data to insert.
 * @returns The created dentist notification or null if insertion failed.
 */
export const createDentistNotification = (
  dentistNotification: DentistNotification,
) =>
  insertRecord<DentistNotification>(
    "dentist_notifications",
    dentistNotification,
  );

/**
 * Updates an existing dentist notification record.
 * @param id - The ID of the dentist notification.
 * @param dentistNotification - The updated dentist notification data.
 * @returns The updated dentist notification or null if update failed.
 */
export const updateDentistNotification = async ({
  id,
  dentistNotification,
}: {
  id: string | null;
  dentistNotification: DentistNotification;
}): Promise<DentistNotification | null> => {
  if (!id) return null;
  return updateRecord<DentistNotification>(
    "dentist_notifications",
    "id",
    id,
    dentistNotification,
  );
};

/**
 * Retrieves a dentist notification by its ID.
 * @param id - The ID of the dentist notification.
 * @returns The dentist notification or null if not found.
 */
export const getDentistNotification = (id: string) =>
  getRecord<DentistNotification>("dentist_notifications", "id", id);

/**
 * Retrieves all dentist notifications associated with a specific dentist ID.
 * @param dentistId - The ID of the dentist.
 * @returns An array of dentist notifications.
 */
export const getDentistNotificationsByDentistId = (dentistId: string) =>
  getRecords<DentistNotification>(
    "dentist_notifications",
    "dentist_id",
    dentistId,
  );

/**
 * Retrieves all unread dentist notifications associated with a specific dentist ID.
 * @param dentistId - The ID of the dentist.
 * @returns An array of unread dentist notifications.
 */
export const getUnreadDentistNotificationsByDentistId = async (
  dentistId: string,
): Promise<DentistNotification[]> => {
  if (!dentistId) return [];

  const { data, error } = await supabase
    .from("dentist_notifications")
    .select("*")
    .eq("dentist_id", dentistId)
    .is("read_at", null);

  if (error) throw new Error(error.message);

  return data ?? [];
};

/**
 * Marks all notifications as read.
 * @param notifications - An array of dentist notifications.
 * @returns An array of updated dentist notifications.
 */
export const readNotifications = async (
  notifications: DentistNotification[],
): Promise<DentistNotification[]> => {
  if (!notifications.length) return [];

  const updatedNotifications = notifications
    .filter((notification) => !notification.read_at)
    .map((notification) => ({
      ...notification,
      read_at: notification.read_at
        ? notification.read_at
        : new Date().toISOString(),
    }));

  await Promise.all(
    updatedNotifications.map((notification) =>
      updateRecord<DentistNotification>(
        "dentist_notifications",
        "id",
        notification.id!,
        notification,
      )
    ),
  );

  return updatedNotifications;
};
