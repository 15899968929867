import { supabase } from "@/utils/supabase";
import { WeeklyReportData } from "@/utils/types";

export interface WeeklyReport {
  id?: string;
  created_at?: string;
  dentist_id: string;
  encoded_data: string;
  start_date: string;
  end_date: string;
  code?: string;
}

/**
 * Retrieves the latest weekly report for a specific dentist.
 * @param dentistId - The ID of the dentist.
 * @returns The latest weekly report or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getLatestWeeklyReportDate = async (
  dentistId: string,
): Promise<string | null> => {
  if (!dentistId) return null;

  const { data, error } = await supabase
    .from("weekly_reports")
    .select("start_date, dentist_id")
    .eq("dentist_id", dentistId)
    .order("start_date", { ascending: false })
    .limit(1)
    .maybeSingle();

  if (error) {
    throw new Error(`Error fetching latest weekly report: ${error.message}`);
  }

  return data?.start_date ?? null;
};

/**
 * Retrieves a weekly report for a specific date range and dentist.
 * @param dentistId - The ID of the dentist.
 * @param startDate - The start date of the report.
 * @param endDate - The end date of the report.
 * @returns The weekly report or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getWeeklyReportByDateAndDentistId = async (
  dentistId: string,
  startDate: string,
): Promise<WeeklyReport | null> => {
  if (!dentistId || !startDate) return null;

  const { data, error } = await supabase
    .from("weekly_reports")
    .select("*")
    .eq("dentist_id", dentistId)
    .eq("start_date", startDate)
    .maybeSingle();

  if (error) {
    throw new Error(`Error fetching weekly report: ${error.message}`);
  }

  return data;
};

/**
 * Decodes the encoded_data field of a weekly report.
 * This function should implement the decoding logic for the weekly report data.
 * @param encodedData - The encoded data string from the weekly report.
 * @returns The decoded data object.
 */
export const decodeWeeklyReportData: (
  encodedData: string,
) => Promise<WeeklyReportData> = async (encodedData: string) => {
  try {
    const ENCRYPTION_KEY = import.meta.env.VITE_WEEKLY_REPORT_ENCRYPTION_KEY;
    const binaryData = Uint8Array.from(
      atob(encodedData),
      (c) => c.charCodeAt(0),
    );

    const iv = binaryData.slice(0, 12);
    const encryptedData = binaryData.slice(12);

    const textEncoder = new TextEncoder();
    const keyBuffer = await crypto.subtle.digest(
      "SHA-256",
      textEncoder.encode(ENCRYPTION_KEY),
    );

    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      keyBuffer,
      { name: "AES-GCM" },
      false,
      ["decrypt"],
    );

    const decryptedData = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv },
      cryptoKey,
      encryptedData,
    );

    const textDecoder = new TextDecoder();
    return JSON.parse(textDecoder.decode(decryptedData));
  } catch (error) {
    throw new Error(`Failed to decode report data: ${error}`);
  }
};

/**
 * Retrieves all unique start and end dates from weekly reports for a specific dentist.
 * @param dentistId - The ID of the dentist.
 * @returns An array of objects containing start_date and end_date.
 * @throws Will throw an error if the retrieval fails.
 */
export const getWeeklyReportDates = async (
  dentistId: string,
): Promise<{ start_date: string; end_date: string }[]> => {
  if (!dentistId) return [];

  const { data, error } = await supabase
    .from("weekly_reports")
    .select("start_date, end_date")
    .eq("dentist_id", dentistId)
    .order("start_date", { ascending: false });

  if (error) {
    throw new Error(`Error fetching weekly report dates: ${error.message}`);
  }

  return data ?? [];
};
