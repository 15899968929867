import { RefObject, useEffect } from "react";

interface UseInputFocusOptions {
  delay?: number;
  shouldFocus?: boolean;
}

export const useInputFocus = (
  inputRef: RefObject<HTMLIonInputElement>,
  options: UseInputFocusOptions = {},
) => {
  const { delay = 240, shouldFocus = true } = options;

  useEffect(() => {
    if (!shouldFocus) return;

    const timer = setTimeout(() => {
      inputRef.current?.setFocus();
    }, delay);

    return () => clearTimeout(timer);
  }, [inputRef, delay, shouldFocus]);
};
