import {
  PurchasesEntitlementInfo,
  VERIFICATION_RESULT,
} from "@revenuecat/purchases-capacitor";

export const ENTITLEMENT_IDENTIFIERS: Record<string, string> = {
  LITE: "lite",
  TEAM: "team",
  ENTERPRISE: "enterprise",
};

export const PLAN_IDENTIFIERS: Record<string, string> = {
  LITE: "lite",
  TEAM: "team",
  ENTERPRISE: "enterprise",
};

export const PLAN_LIMITS: Record<string, number> = {
  LITE: 2,
  TEAM: 6,
  ENTERPRISE: 50,
  DEFAULT: 0,
};

export const PLAN_NAMES: Record<string, string> = {
  lite_monthly: "Lite",
  team_monthly: "Team",
  enterprise_monthly: "Enterprise",
};

export const PLAN_PRICES_STRING: Record<string, string> = {
  lite_monthly: "999",
  team_monthly: "1,299",
  enterprise_monthly: "1,499",
};

export const PLAN_DESCRIPTIONS: Record<string, string> = {
  lite_monthly: "For clinics with 1-2 dentists.",
  team_monthly: "For clinics with 3-6 dentists.",
  enterprise_monthly: "For clinics with 7+ dentists.",
};

export const FREE_USER_IDS: string[] = [
  "a2ae653a-23a7-4f00-bd8c-458128460192", // Happy Green Dental Center - Cainta
  "f7824516-9403-40b8-92d1-2d9339149a90", // Happy Green Dental Center - Lower Antipolo
  "b601e9a4-c0a4-4426-ba11-1b186c7d0ff3", // Nunez Dental Clinic
];

export const FREE_USER_ENTITLEMENT: PurchasesEntitlementInfo = {
  identifier: ENTITLEMENT_IDENTIFIERS.ENTERPRISE,
  isActive: true,
  willRenew: true,
  periodType: "NORMAL",
  latestPurchaseDate: new Date().toISOString(),
  latestPurchaseDateMillis: new Date().getTime(),
  originalPurchaseDate: new Date().toISOString(),
  originalPurchaseDateMillis: new Date().getTime(),
  expirationDate: null,
  expirationDateMillis: null,
  store: "EXTERNAL",
  productIdentifier: "free",
  productPlanIdentifier: "free",
  isSandbox: false,
  unsubscribeDetectedAt: null,
  unsubscribeDetectedAtMillis: null,
  billingIssueDetectedAt: null,
  billingIssueDetectedAtMillis: null,
  ownershipType: "PURCHASED",
  verification: "NOT_REQUESTED" as VERIFICATION_RESULT,
};
