import {
  checkmarkCircle,
  checkmarkCircleOutline,
  closeCircle,
  closeCircleOutline,
  hourglass,
  hourglassOutline,
  removeCircle,
  removeCircleOutline,
  time,
  timeOutline,
} from "ionicons/icons";

// Authentication & User
export const MOBILE_NUMBER_LENGTH: number = 10;
export const OTP_LENGTH: number = 6;

export const FETCH_USER_TIMEOUT_IN_MS: number = 10000;
export const SIGN_IN_TIMEOUT_IN_MS: number = 10000;
export const SIGN_OUT_TIMEOUT_IN_MS: number = 10000;
export const REFRESH_TIMEOUT_IN_MS: number = 240;

export const SIGN_IN_STEPS: { [key: string]: string } = {
  MOBILE_NUMBER: "mobileNumber",
  VERIFICATION: "verification",
};

export const GET_STARTED_STEPS: { [key: string]: string } = {
  CLINIC_NAME: "clinicName",
  MOBILE_NUMBER: "mobileNumber",
  VERIFICATION: "verification",
};

// UI Constants
export const WELCOME_PAGE_MINIMUM_HEIGHT_FOR_IMAGE: number = 842;
export const ENTRIES_PER_PAGE: number = 3;

export const AVATAR_COLORS: { [key: string]: string } = {
  BLUE: "blue",
  GREEN: "green",
  ORANGE: "orange",
  YELLOW: "yellow",
  BLACK: "black",
  VIOLET: "violet",
};

// Request & Appointment Status
export const REQUEST_STATUS_OPTIONS: { [key: string]: string } = {
  PENDING: "pending",
  APPROVED: "approved",
  DECLINED: "declined",
  RESCHEDULED: "rescheduled",
  CANCELLED: "cancelled",
};

export const ATTENDANCE_STATUS_OPTIONS: { [key: string]: string } = {
  DEFAULT: "default",
  NO_SHOW: "no-show",
  LATE: "late",
};

export const REQUEST_ACTIONS: { [key: string]: string } = {
  APPROVE: "approve",
  DECLINE: "decline",
  RESCHEDULE: "reschedule",
  CANCEL: "cancel",
  MARK_AS_LATE: "mark_as_late",
  MARK_AS_NO_SHOW: "mark_as_no_show",
};

export const REQUEST_ACTION_ICONS: { [key: string]: string } = {
  approve: checkmarkCircleOutline,
  decline: closeCircleOutline,
  reschedule: timeOutline,
  cancel: removeCircleOutline,
};

export const REQUEST_STATUS_ICONS: { [key: string]: string } = {
  pending: hourglassOutline,
  approved: checkmarkCircleOutline,
  declined: closeCircleOutline,
  rescheduled: timeOutline,
  cancelled: removeCircleOutline,
};

export const REQUEST_STATUS_ACTIVE_ICONS: { [key: string]: string } = {
  pending: hourglass,
  approved: checkmarkCircle,
  declined: closeCircle,
  rescheduled: time,
  cancelled: removeCircle,
};

export const REQUEST_EXPIRATION_BUFFER_IN_MINUTES: number = 30;
export const FETCH_REQUESTS_TIMEOUT_IN_MS: number = 240;

// User Profile & Settings
export const GENDER_OPTIONS: { [key: string]: string } = {
  MALE: "male",
  FEMALE: "female",
  OTHER: "other",
};

export const GENDER_LABELS: { [key: string]: string } = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Prefer not to say",
};

export const ACCESS_TYPES: { [key: string]: string } = {
  EDIT: "edit",
  VIEW: "view",
};

// Dentist & Services
export const ADD_DENTIST_STEPS: { [key: string]: string } = {
  NAME: "name",
  MOBILE_NUMBER: "mobileNumber",
  HOURS: "hours",
  SERVICES: "services",
};

export const DAY_NAMES_ARRAY: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const SERVICE_DURATION_OPTIONS: { label: string; value: number }[] = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "45 minutes", value: 45 },
  { label: "1 hour", value: 60 },
  { label: "1 hour 15 minutes", value: 75 },
  { label: "1 hour 30 minutes", value: 90 },
  { label: "1 hour 45 minutes", value: 105 },
  { label: "2 hours", value: 120 },
  { label: "2 hours 15 minutes", value: 135 },
  { label: "2 hours 30 minutes", value: 150 },
  { label: "2 hours 45 minutes", value: 165 },
  { label: "3 hours", value: 180 },
  { label: "3 hours 15 minutes", value: 195 },
  { label: "3 hours 30 minutes", value: 210 },
  { label: "3 hours 45 minutes", value: 225 },
  { label: "4 hours", value: 240 },
  { label: "4 hours 15 minutes", value: 255 },
  { label: "4 hours 30 minutes", value: 270 },
  { label: "4 hours 45 minutes", value: 285 },
  { label: "5 hours", value: 300 },
  { label: "5 hours 15 minutes", value: 315 },
  { label: "5 hours 30 minutes", value: 330 },
  { label: "5 hours 45 minutes", value: 345 },
  { label: "6 hours", value: 360 },
  { label: "6 hours 15 minutes", value: 375 },
  { label: "6 hours 30 minutes", value: 390 },
  { label: "6 hours 45 minutes", value: 405 },
  { label: "7 hours", value: 420 },
  { label: "7 hours 15 minutes", value: 435 },
  { label: "7 hours 30 minutes", value: 450 },
  { label: "7 hours 45 minutes", value: 465 },
  { label: "8 hours", value: 480 },
];

export const SPECIALIZATION_OPTIONS: { [key: string]: string } = {
  ANESTHESIOLOGY: "Dental Anesthesiology",
  COSMETIC: "Cosmetic Dentistry",
  ENDODONTICS: "Endodontics",
  GENERAL: "General Dentistry",
  GERIATRIC: "Geriatric Dentistry",
  IMPLANTOLOGY: "Implantology",
  ORTHODONTICS: "Orthodontics",
  PATHOLOGY: "Oral Pathology",
  PEDIATRICS: "Pediatric Dentistry",
  PERIODONTICS: "Periodontics",
  PROSTHODONTICS: "Prosthodontics",
  RADIOLOGY: "Oral Radiology",
  RESTORATIVE: "Restorative Dentistry",
  SURGERY: "Oral Surgery",
  TMJ: "TMJ Specialist",
};

// Notifications & Reports
export const NOTIFICATION_CATEGORIES: { [key: string]: string } = {
  REMINDER: "reminder",
  REQUEST: "request",
  CANCELLED: "cancelled",
  RESCHEDULED: "rescheduled",
};

export const NOTIFICATION_CATEGORY_IDENTIFIERS: { [key: string]: string } = {
  REMINDER: "Reminder: ",
  REQUEST: "requested",
  CANCELLED: "cancelled",
  RESCHEDULED: "rescheduled",
};

export const NOTIFICATION_TITLES: { [key: string]: string } = {
  REMINDER: "You have an upcoming appointment!",
  REQUEST: "You just received a new request!",
  CANCELLED: "Your appointment has been cancelled!",
  RESCHEDULED: "Your appointment has been rescheduled!",
};

export const WEEKLY_REPORT_SUBSCRIPTION_TYPES: { [key: string]: string } = {
  CLINIC: "clinic",
  PERSONAL: "personal",
  NONE: "none",
};

// Calendar & Time
export const CALENDAR_MONTHS_DISPLAY_RANGE_PAST: number = 1;
export const CALENDAR_MONTHS_DISPLAY_RANGE_FUTURE: number = 3;

// Misc
export const SERVICE_JOIN_SEPARATOR: string = ", ";
export const PURPOSE_JOIN_SEPARATOR: string = ", ";
export const SPECIALIZATION_JOIN_SEPARATOR: string = ", ";
export const SELECT_MODAL_OTHERS: string = "Others";
export const OFF_DUTY_PATIENT_ID: string =
  "ad12af66-5edb-4d67-a91e-bd73d96d074d";

// Platform & URLs
export const PLATFORMS: { [key: string]: string } = {
  IOS: "ios",
  ANDROID: "android",
  WEB: "web",
};

export const APP_STORE_ID: string = "6737960471";
export const HELP_URL: string = "https://www.odonto.ph/help";
export const TERMS_OF_SERVICE_URL: string =
  "https://www.odonto.ph/terms-of-service";
export const PRIVACY_POLICY_URL: string =
  "https://www.odonto.ph/privacy-policy";

// Weekly Report
export const WEEKLY_REPORT_DEFAULT_DENTIST: string = "All Dentists";
export const WEEKLY_REPORT_DEFAULT_CHART_WIDTH: number = 320;
export const WEEKLY_REPORT_DEFAULT_CHART_HEIGHT: number = 240;
export const WEEKLY_REPORT_DEFAULT_CHART_MAX_WIDTH: number = 720;
export const AVERAGE_REVENUE_PER_APPOINTMENT: number = 800;
