import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { App } from '@capacitor/app';
import { RealtimeChannel } from '@supabase/supabase-js';

import { useDentist } from '@/contexts/DentistContext';
import { useRequests } from '@/contexts/RequestsContext';
import {
  DentistNotification,
  getDentistNotificationsByDentistId,
  readNotifications
} from '@/data/DentistNotifications';
import { Dentist } from '@/data/Dentists';
import useToast from '@/hooks/useToast';
import {
  ACCESS_TYPES,
  NOTIFICATION_CATEGORY_IDENTIFIERS,
  REQUEST_EXPIRATION_BUFFER_IN_MINUTES,
  REQUEST_STATUS_OPTIONS
} from '@/utils/constants';
import { supabase } from '@/utils/supabase';
import moment from 'moment';

interface NotificationsContextType {
  notifications: DentistNotification[];
  fetchNotifications: ({ force }: { force?: boolean }) => Promise<void>;
  groupedNotifications: {
    unread: DentistNotification[];
    read: DentistNotification[];
    readByDate: Record<string, DentistNotification[]>;
  };
  readNotification: () => Promise<void>;
  unreadCount: number;
}

const NotificationsContext = createContext<
  NotificationsContextType | undefined
>(undefined);

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { selectedDentist: dentist, setDentists } = useDentist();
  const { requests } = useRequests();
  const displayToast = useToast();

  const [notifications, setNotifications] = useState<DentistNotification[]>([]);
  const [channel, setChannel] = useState<RealtimeChannel | null>(null);

  const previousDentistIdRef = useRef<string | null>(null);

  const fetchNotifications = useCallback(
    async ({ force = false }: { force?: boolean } = {}) => {
      if (!dentist?.id) return;

      if (dentist.id === previousDentistIdRef.current && !force) return;

      previousDentistIdRef.current = dentist.id;

      try {
        const fetchedNotifications = await getDentistNotificationsByDentistId(
          dentist.id
        );

        const expirationTime = moment().add(
          REQUEST_EXPIRATION_BUFFER_IN_MINUTES,
          'minutes'
        );

        const validNotifications = fetchedNotifications.filter(
          (notification) => {
            const request = requests.find(
              (request) => request.id === notification.request_id
            );

            if (!request) return true;

            const requestStartTime = moment(
              `${request.date} ${request.start_time}`,
              'YYYY-MM-DD HH:mm'
            );

            const isRequestExpired =
              requestStartTime.isBefore(expirationTime) &&
              request.status === REQUEST_STATUS_OPTIONS.PENDING;

            return !isRequestExpired;
          }
        );
        setNotifications(validNotifications);
      } catch {
        displayToast({
          message: 'Please check your internet connection.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    },
    [dentist?.id]
  );

  const stream = useCallback(async () => {
    if (channel) supabase.removeChannel(channel);

    if (!dentist?.id) {
      setChannel(null);
      return;
    }

    const channelName = `notifications${dentist.id?.replace(
      /-/g,
      ''
    )}${moment().unix()}`;

    const newChannel = supabase
      .channel(channelName)
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'dentist_notifications',
          filter: `dentist_id=eq.${dentist.id}`
        },
        (payload) => {
          const newNotification = payload.new;
          const request = requests.find(
            (request) => request.id === newNotification.request_id
          );

          if (!request) {
            setNotifications((prevNotifications) => [
              ...prevNotifications,
              newNotification as DentistNotification
            ]);
            return;
          }

          const expirationTime = moment().add(
            REQUEST_EXPIRATION_BUFFER_IN_MINUTES,
            'minutes'
          );

          const requestStartTime = moment(
            `${request.date} ${request.start_time}`,
            'YYYY-MM-DD HH:mm'
          );

          const isRequestExpired =
            requestStartTime.isBefore(expirationTime) &&
            request.status === REQUEST_STATUS_OPTIONS.PENDING;

          if (isRequestExpired) return;

          setNotifications((prevNotifications) => [
            ...prevNotifications,
            newNotification as DentistNotification
          ]);
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          fetchNotifications({ force: true });
        }
      });

    setChannel(newChannel);
  }, [dentist?.id, fetchNotifications]);

  useEffect(() => {
    if (!channel) stream();

    App.addListener('pause', () => {
      if (channel) {
        supabase.removeChannel(channel);
        setChannel(null);
      }
    });

    App.addListener('resume', () => {
      if (!channel) stream();
    });

    return () => {
      App.removeAllListeners();
      if (channel) {
        supabase.removeChannel(channel);
        setChannel(null);
      }
    };
  }, [channel, stream]);

  const readNotification = useCallback(async () => {
    if (dentist?.id) {
      const notificationsToRead =
        dentist.access === ACCESS_TYPES.EDIT
          ? notifications
          : notifications.filter((notification) => {
              const isRequestNotification = notification.message.includes(
                NOTIFICATION_CATEGORY_IDENTIFIERS.REQUEST
              );
              return !isRequestNotification;
            });
      const updatedNotifications = await readNotifications(notificationsToRead);
      setNotifications((prevNotifications) => [
        ...prevNotifications.filter(
          (n) => !updatedNotifications.some((un) => un.id === n.id)
        ),
        ...updatedNotifications
      ]);
      setDentists((previousDentists: Dentist[]) => {
        return previousDentists.map((dentist: Dentist) =>
          dentist.id === dentist?.id ? { ...dentist, badge: 0 } : dentist
        );
      });
    }
  }, [dentist?.id, notifications]);

  const groupedNotifications = useMemo(() => {
    const unreadNotifications = notifications
      .filter((notification) => !notification.read_at)
      .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

    const readNotifications = notifications.filter(
      (notification) => notification.read_at
    );

    const readNotificationsByDate = readNotifications.reduce(
      (acc, notification) => {
        const date = moment(notification.created_at).format('YYYY-MM-DD');
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(notification);
        return acc;
      },
      {} as Record<string, DentistNotification[]>
    );

    Object.keys(readNotificationsByDate).forEach((date) => {
      readNotificationsByDate[date].sort((a, b) =>
        moment(b.created_at).diff(moment(a.created_at))
      );
    });

    const sortedReadNotificationsByDate = Object.keys(readNotificationsByDate)
      .sort((a, b) => moment(b).diff(moment(a)))
      .reduce(
        (acc, date) => {
          acc[date] = readNotificationsByDate[date];
          return acc;
        },
        {} as Record<string, DentistNotification[]>
      );

    return {
      unread: unreadNotifications,
      read: readNotifications,
      readByDate: sortedReadNotificationsByDate
    };
  }, [notifications]);

  const unreadCount = useMemo(
    () => notifications.filter((notification) => !notification.read_at).length,
    [notifications]
  );

  useEffect(() => {
    return () => {
      setNotifications([]);
      setChannel(null);
    };
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        fetchNotifications,
        groupedNotifications,
        readNotification,
        unreadCount
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationsProvider'
    );
  }
  return context;
};
