import { Patient } from "./Patients";
import {
  ATTENDANCE_STATUS_OPTIONS,
  CALENDAR_MONTHS_DISPLAY_RANGE_FUTURE,
  CALENDAR_MONTHS_DISPLAY_RANGE_PAST,
  REQUEST_STATUS_OPTIONS,
} from "@/utils/constants";
import {
  getRecord,
  getRecords,
  insertRecord,
  updateRecord,
} from "@/utils/helpers/data";
import { supabase } from "@/utils/supabase";
import { AttendanceStatus, RequestStatus } from "@/utils/types";
import moment from "moment";

/**
 * Represents a request made by a patient or dentist.
 */
export interface Request {
  id?: string;
  date: string | null;
  start_time: string | null;
  end_time: string | null;
  purpose: string | null;
  status: RequestStatus;
  created_at?: string;
  updated_at?: string;
  dentist_id: string | null;
  patient_id: string | null;
  patient?: Patient;
  cancel_reason?: string | null;
  code?: string | null;
  reschedule_reason?: string | null;
  decline_reason?: string | null;
  attendance_status?: AttendanceStatus;
}

/**
 * Creates a new request record in the database.
 * @param request - The request data to insert.
 * @returns The created request or null if insertion failed.
 * @throws Will throw an error if the insertion fails.
 */
export const createRequest = async (
  request: Request,
): Promise<Request | null> => {
  if (!Object.values(REQUEST_STATUS_OPTIONS).includes(request.status)) {
    return null;
  }

  return insertRecord<Request>("requests", request);
};

/**
 * Updates an existing request record in the database.
 * @param params - Contains the request ID and the updated request data.
 * @returns The updated request or null if update failed.
 * @throws Will throw an error if the update fails.
 */
export const updateRequest = async ({
  requestId,
  request,
}: {
  requestId: string | null | undefined;
  request: Request;
}): Promise<Request | null> => {
  if (!requestId) return null;

  if (!Object.values(REQUEST_STATUS_OPTIONS).includes(request.status)) {
    return null;
  }

  return updateRecord<Request>("requests", "id", requestId, request);
};

/**
 * Retrieves a single request by its ID.
 * @param requestId - The ID of the request to retrieve.
 * @returns The requested record or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getRequest = async (
  requestId: string | null | undefined,
): Promise<Request | null> => {
  if (!requestId) return null;

  return getRecord<Request>("requests", "id", requestId);
};

/**
 * Retrieves all requests associated with a specific dentist ID.
 * @param dentistId - The ID of the dentist.
 * @returns An array of requests.
 * @throws Will throw an error if the retrieval fails.
 */
export const getRequestsByDentistId = async (
  dentistId: string | null | undefined,
): Promise<Request[]> => {
  if (!dentistId) return [];

  return getRecords<Request>("requests", "dentist_id", dentistId);
};

/**
 * Retrieves all requests for a specific dentist ID and status.
 * @param dentistId - The ID of the dentist.
 * @param status - The status of the requests.
 * @returns An array of requests matching the criteria.
 * @throws Will throw an error if the retrieval fails.
 */
export const getRequestsByDentistIdAndStatus = async (
  dentistId: string | null | undefined,
  status: string,
): Promise<Request[]> => {
  if (!dentistId || !status) return [];
  if (!Object.values(REQUEST_STATUS_OPTIONS).includes(status)) return [];

  const { data, error } = await supabase
    .from("requests")
    .select("*")
    .eq("dentist_id", dentistId)
    .eq("status", status);

  if (error) throw new Error(error.message);

  return data ?? [];
};

/**
 * Retrieves all requests associated with a specific patient ID and status.
 * @param patientId - The ID of the patient.
 * @param status - The status of the requests.
 * @returns An array of requests matching the criteria.
 * @throws Will throw an error if the retrieval fails.
 */
export const getRequestsByPatientIdAndStatus = async (
  patientId: string | null | undefined,
  status: string,
): Promise<Request[]> => {
  if (!patientId || !status) return [];
  if (!Object.values(REQUEST_STATUS_OPTIONS).includes(status)) return [];

  const { data, error } = await supabase
    .from("requests")
    .select("*")
    .eq("patient_id", patientId)
    .eq("status", status);

  if (error) throw new Error(error.message);

  return data ?? [];
};

/**
 * Retrieves all requests based on patient ID, dentist ID, and status.
 * @param params - Contains patientId, dentistId, and status.
 * @returns An array of requests matching the criteria.
 * @throws Will throw an error if the retrieval fails.
 */
export const getRequestsByReferenceAndStatus = async ({
  patientId,
  dentistId,
  status,
}: {
  patientId: string | null | undefined;
  dentistId: string | null | undefined;
  status: string | null | undefined;
}): Promise<Request[]> => {
  if (!patientId || !dentistId || !status) return [];
  if (!Object.values(REQUEST_STATUS_OPTIONS).includes(status)) return [];

  const { data, error } = await supabase
    .from("requests")
    .select("*")
    .eq("patient_id", patientId)
    .eq("dentist_id", dentistId)
    .eq("status", status);

  if (error) throw new Error(error.message);

  return data ?? [];
};

/**
 * Approves a request by updating its status to 'APPROVED'.
 * @param requestId - The ID of the request to approve.
 * @throws Will throw an error if the update fails.
 */
export const approveRequest = async (requestId: string): Promise<void> => {
  await updateRecord<Request>("requests", "id", requestId, {
    status: REQUEST_STATUS_OPTIONS.APPROVED,
  });
};

/**
 * Declines a request by updating its status to 'DECLINED' and sets a reason for the decline.
 * @param requestId - The ID of the request to decline.
 * @param reason - The reason for declining the request (optional).
 * @throws Will throw an error if the update fails.
 */
export const declineRequest = async (
  requestId: string,
  reason?: string | null,
): Promise<void> => {
  await updateRecord<Request>("requests", "id", requestId, {
    status: REQUEST_STATUS_OPTIONS.DECLINED,
    decline_reason: reason ?? null,
  });
};

/**
 * Cancels a request by updating its status to 'CANCELLED' and sets a reason for the cancellation.
 * @param requestId - The ID of the request to cancel.
 * @param reason - The reason for canceling the request (optional).
 * @throws Will throw an error if the update fails.
 */
export const cancelRequest = async (
  requestId: string,
  reason?: string | null,
): Promise<void> => {
  await updateRecord<Request>("requests", "id", requestId, {
    status: REQUEST_STATUS_OPTIONS.CANCELLED,
    cancel_reason: reason ?? null,
  });
};

/**
 * Reschedules a request by updating its status to 'RESCHEDULED', setting a new date and time, and a reason for the reschedule.
 * @param requestId - The ID of the request to reschedule.
 * @param reason - The reason for rescheduling the request (optional).
 * @throws Will throw an error if the update fails.
 */
export const rescheduleRequest = async (
  requestId: string,
  reason?: string | null,
): Promise<void> => {
  await updateRecord<Request>("requests", "id", requestId, {
    status: REQUEST_STATUS_OPTIONS.RESCHEDULED,
    reschedule_reason: reason ?? null,
  });
};

/**
 * Retrieves all requests for the dashboard based on dentist ID.
 * @param dentistId - The ID of the dentist.
 * @param startDate - The start date of the range.
 * @param endDate - The end date of the range.
 * @returns An array of requests for the dashboard.
 * @throws Will throw an error if the retrieval fails.
 */
export const getRequestsForDashboard = async ({
  dentistId,
  startDate,
  endDate,
}: {
  dentistId: string | null | undefined;
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
}): Promise<Request[]> => {
  if (!dentistId) return [];

  const start = startDate ?? moment()
    .subtract(CALENDAR_MONTHS_DISPLAY_RANGE_PAST, "months")
    .startOf("week")
    .format("YYYY-MM-DD");

  const end = endDate ?? moment()
    .add(CALENDAR_MONTHS_DISPLAY_RANGE_FUTURE, "months")
    .endOf("week")
    .format("YYYY-MM-DD");

  const { data, error } = await supabase
    .from("requests")
    .select("*, patient:patient_id (*)")
    .eq("dentist_id", dentistId)
    .or(
      `and(created_at.gte.${start},created_at.lte.${end}),` +
        `and(date.gte.${start},date.lte.${end})`,
    );

  if (error) throw new Error(error.message);

  return data ?? [];
};

/**
 * Retrieves all requests for a specific day and dentist.
 * @param dentistId - The ID of the dentist.
 * @param date - The date to get appointments for.
 * @returns An array of requests for the specified day.
 * @throws Will throw an error if the retrieval fails.
 */
export const getAppointmentsForDay = async (
  dentistId: string,
  date: string,
): Promise<Request[]> => {
  if (!dentistId) return [];

  const { data, error } = await supabase
    .from("requests")
    .select("*, patient:patient_id (*)")
    .eq("dentist_id", dentistId)
    .eq("date", date)
    .eq("status", REQUEST_STATUS_OPTIONS.APPROVED);

  if (error) throw new Error(error.message);

  return data ?? [];
};

/**
 * Updates the attendance status of a request.
 * @param requestId - The ID of the request to update.
 * @param status - The new attendance status.
 * @throws Will throw an error if the update fails.
 */
export const updateAttendanceStatus = async (
  requestId: string,
  status?: AttendanceStatus | null,
): Promise<void> => {
  if (status && !Object.values(ATTENDANCE_STATUS_OPTIONS).includes(status)) {
    throw new Error(`Invalid attendance status: ${status}`);
  }

  await updateRecord<Request>("requests", "id", requestId, {
    attendance_status: status ?? ATTENDANCE_STATUS_OPTIONS.DEFAULT,
  });
};
