import { IonIcon, IonText } from '@ionic/react';
import React from 'react';

import Spacer from '@/components/common/spacer/Spacer';
import { checkmarkCircle } from 'ionicons/icons';

import './Benefits.css';

const Benefits: React.FC = () => {
  const benefits = [
    'Unlimited appointments',
    'Unlimited patient reminders',
    'Exclusive booking website',
    'All features included'
  ];

  return (
    <div className="benefits">
      {benefits.map((benefit, index) => (
        <div className="benefit" key={`benefit-${index}`}>
          <IonIcon
            className="benefitIcon"
            icon={checkmarkCircle}
            color="success"
          />
          <p className="benefitText">{benefit}</p>
        </div>
      ))}
    </div>
  );
};

export default Benefits;
