import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useNetwork } from './NetworkContext';
import { getAllSupportedVersions } from '@/data/SupportedVersions';
import useToast from '@/hooks/useToast';
import { APP_VERSION } from '@/utils/version';

interface VersionContextProps {
  forceUpdate: boolean | null;
  maintenanceModeEnabled: boolean | null;
  supportedVersions: string[];
}

const VersionContext = createContext<VersionContextProps | undefined>(
  undefined
);

export const VersionProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const displayToast = useToast();
  const { connected } = useNetwork();

  const [forceUpdate, setForceUpdate] = useState<boolean | null>(null);
  const [maintenanceModeEnabled, setMaintenanceModeEnabled] = useState<
    boolean | null
  >(null);
  const [supportedVersions, setSupportedVersions] = useState<string[]>([]);

  const fetchSupportedVersions = useCallback(async () => {
    if (!connected) return;
    try {
      const supportedVersions = await getAllSupportedVersions();
      setSupportedVersions(
        supportedVersions.map((supportedVersion) => supportedVersion.version)
      );

      const currentVersion = supportedVersions.find(
        (supportedVersion) => supportedVersion.version === APP_VERSION
      );

      if (!currentVersion) {
        setForceUpdate(true);
      } else {
        setForceUpdate(false);
        setMaintenanceModeEnabled(currentVersion.maintenance_mode_enabled);
      }
    } catch {
      displayToast({
        message: 'Please check your internet connection.',
        duration: 5000,
        position: 'bottom'
      });
    }
  }, [connected]);

  useEffect(() => {
    fetchSupportedVersions();
  }, [fetchSupportedVersions]);

  useEffect(() => {
    return () => {
      setForceUpdate(null);
      setMaintenanceModeEnabled(null);
      setSupportedVersions([]);
    };
  }, []);

  return (
    <VersionContext.Provider
      value={{ forceUpdate, maintenanceModeEnabled, supportedVersions }}
    >
      {children}
    </VersionContext.Provider>
  );
};

export const useVersion = (): VersionContextProps => {
  const context = useContext(VersionContext);
  if (!context) {
    throw new Error('useVersion must be used within a VersionProvider');
  }
  return context;
};
