import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { Capacitor } from '@capacitor/core';

import { useAuth } from '@/contexts/AuthenticationContext';
import { useNetwork } from '@/contexts/NetworkContext';
import useToast from '@/hooks/useToast';
import { PLATFORMS } from '@/utils/constants';
import {
  ENTITLEMENT_IDENTIFIERS,
  FREE_USER_ENTITLEMENT,
  FREE_USER_IDS,
  PLAN_LIMITS
} from '@/utils/subscriptions';
import {
  LOG_LEVEL,
  Purchases,
  PurchasesEntitlementInfo,
  PurchasesEntitlementInfos,
  PurchasesPackage,
  VERIFICATION_RESULT
} from '@revenuecat/purchases-capacitor';

interface SubscriptionContextType {
  loading: boolean;
  purchasing: boolean;
  isSubscribed: boolean | undefined;
  checkEligibilityForTrial: () => Promise<void>;
  isEligibleForTrial: boolean | undefined;
  login: (userId: string) => Promise<void>;
  logout: () => Promise<void>;
  plans: PurchasesPackage[];
  selectedPlan: PurchasesPackage | undefined;
  setSelectedPlan: (plan: PurchasesPackage) => void;
  purchase: () => Promise<void>;
  restore: () => Promise<void>;
  profileCountLimit: number;
  entitlement: PurchasesEntitlementInfo | undefined;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(
  undefined
);

interface SubscriptionProviderProps {
  children: React.ReactNode;
}

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({
  children
}) => {
  const displayToast = useToast();
  const { authenticated, user } = useAuth();
  const { connected } = useNetwork();
  const [loading, setLoading] = useState<boolean>(false);
  const [purchasing, setPurchasing] = useState<boolean>(false);
  const [isConfigured, setIsConfigured] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean | undefined>(
    undefined
  );
  const [isEligibleForTrial, setIsEligibleForTrial] = useState<
    boolean | undefined
  >(undefined);
  const [plans, setPlans] = useState<PurchasesPackage[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<
    PurchasesPackage | undefined
  >(undefined);
  const [entitlements, setEntitlements] = useState<PurchasesEntitlementInfos>({
    all: {},
    active: {},
    verification: 'NOT_REQUESTED' as VERIFICATION_RESULT
  });
  const [customerInfoListener, setCustomerInfoListener] = useState<
    string | undefined
  >(undefined);
  const platform = Capacitor.getPlatform();

  const handleError = (
    error: any,
    message: string,
    showMessage: boolean = false
  ) => {
    console.error(message, error);
    if (showMessage) {
      displayToast({
        message,
        position: 'bottom'
      });
    }
  };

  const configure = useCallback(async () => {
    if (!connected || isConfigured || platform === PLATFORMS.WEB) {
      return;
    }

    try {
      // await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
      await Purchases.configure({
        apiKey:
          platform === PLATFORMS.IOS
            ? import.meta.env.VITE_REVENUE_CAT_IOS_KEY
            : import.meta.env.VITE_REVENUE_CAT_ANDROID_KEY
      });
      setIsConfigured(true);
    } catch (error) {
      handleError(error, 'Error configuring RevenueCat:');
    }
  }, [connected, platform, isConfigured]);

  const checkEligibilityForTrial = useCallback(async () => {
    if (platform === PLATFORMS.WEB || !connected || !isConfigured) {
      return;
    }

    // Free trial for first time users
    setIsEligibleForTrial(Object.keys(entitlements.all).length === 0);
  }, [connected, isConfigured, platform, entitlements.all]);

  const checkSubscriptionStatus = useCallback(async () => {
    try {
      const { customerInfo } = await Purchases.getCustomerInfo();
      const { entitlements: fetchedEntitlements } = customerInfo;
      if (fetchedEntitlements) {
        setIsSubscribed(Object.keys(fetchedEntitlements.active).length > 0);
        setEntitlements(fetchedEntitlements);
      }

      const listener = await Purchases.addCustomerInfoUpdateListener(
        ({ entitlements: updatedEntitlements }) => {
          if (updatedEntitlements) {
            setIsSubscribed(Object.keys(updatedEntitlements.active).length > 0);
            setEntitlements(updatedEntitlements);
          }
        }
      );

      setCustomerInfoListener(listener);
    } catch (error) {
      handleError(error, 'Error checking subscription status:');
    }
  }, []);

  const login = useCallback(
    async (userId: string) => {
      if (platform === PLATFORMS.WEB || !connected || !isConfigured) {
        setLoading(false);
        return;
      }

      if (FREE_USER_IDS.includes(userId)) {
        setIsSubscribed(true);
        setEntitlements({
          all: {
            [ENTITLEMENT_IDENTIFIERS.ENTERPRISE]: FREE_USER_ENTITLEMENT
          },
          active: {
            [ENTITLEMENT_IDENTIFIERS.ENTERPRISE]: FREE_USER_ENTITLEMENT
          },
          verification: 'NOT_REQUESTED' as VERIFICATION_RESULT
        });
        return;
      }

      try {
        setLoading(true);
        await Purchases.logIn({ appUserID: userId });
        await checkSubscriptionStatus();
      } catch (error) {
        handleError(error, 'Error logging in to RevenueCat:');
      } finally {
        setLoading(false);
      }
    },
    [connected, platform, isConfigured, checkSubscriptionStatus]
  );

  const logout = useCallback(async () => {
    if (platform === PLATFORMS.WEB || !connected || !isConfigured) {
      return;
    }

    try {
      const isAnonymous = await Purchases.isAnonymous();
      if (!isAnonymous) {
        await Purchases.logOut();
      }
      setIsSubscribed(false);
      setEntitlements({
        all: {},
        active: {},
        verification: 'NOT_REQUESTED' as VERIFICATION_RESULT
      });
    } catch (error) {
      handleError(error, 'Error logging out from RevenueCat:');
    }
  }, [connected, platform, isConfigured]);

  const fetchPlans = useCallback(async () => {
    if (platform === PLATFORMS.WEB || !connected || !isConfigured) {
      return;
    }

    try {
      const offerings = await Purchases.getOfferings();
      if (
        offerings.current !== null &&
        offerings.current.availablePackages.length > 0
      ) {
        setPlans(
          offerings.current.availablePackages.sort(
            (a, b) => a.product.price - b.product.price
          )
        );
        setSelectedPlan(
          offerings.current.availablePackages.sort(
            (a, b) => a.product.price - b.product.price
          )[0]
        );
      }
    } catch (error) {
      handleError(error, 'Error fetching plans:');
    }
  }, [connected, platform, isConfigured]);

  const purchase = useCallback(async () => {
    if (platform === PLATFORMS.WEB || !connected || !isConfigured) {
      return;
    }

    if (!selectedPlan) {
      displayToast({
        message: 'Please select a plan to continue.',
        position: 'top'
      });
      return;
    }

    try {
      setPurchasing(true);
      await Purchases.purchasePackage({ aPackage: selectedPlan });
    } catch (error) {
      handleError(
        error,
        'Subscription failed. Please try again or contact support if the issue persists.',
        true
      );
    } finally {
      setPurchasing(false);
    }
  }, [connected, platform, selectedPlan, isConfigured]);

  const restore = useCallback(async () => {
    if (platform === PLATFORMS.WEB || !connected || !isConfigured) {
      return;
    }

    try {
      setPurchasing(true);
      await Purchases.restorePurchases();
    } catch (error) {
      handleError(
        error,
        'Error restoring purchases. Please contact support.',
        true
      );
    } finally {
      setPurchasing(false);
    }
  }, [connected, platform, isConfigured]);

  const entitlement = useMemo(() => {
    if (
      Object.keys(entitlements.active).length === 0 ||
      !isConfigured ||
      platform === PLATFORMS.WEB
    ) {
      return undefined;
    }

    if (Object.keys(entitlements.active).length === 1) {
      return Object.values(entitlements.active)[0];
    }

    return Object.values(entitlements.active).reduce(
      (
        latest: PurchasesEntitlementInfo,
        entitlement: PurchasesEntitlementInfo
      ) => {
        if (!entitlement || !latest) {
          return Object.values(entitlements.active)[0];
        }

        return new Date(entitlement.latestPurchaseDate) >
          new Date(latest.latestPurchaseDate)
          ? entitlement
          : latest;
      },
      Object.values(entitlements.active)[0]
    );
  }, [
    Object.keys(entitlements.active).length,
    Object.values(entitlements.active),
    isConfigured,
    platform
  ]);

  const profileCountLimit = useMemo(() => {
    if (
      plans.length === 0 ||
      !isConfigured ||
      platform === PLATFORMS.WEB ||
      !entitlement
    ) {
      return PLAN_LIMITS.DEFAULT;
    }

    if (entitlement.identifier === ENTITLEMENT_IDENTIFIERS.LITE) {
      return PLAN_LIMITS.LITE;
    }

    if (entitlement.identifier === ENTITLEMENT_IDENTIFIERS.TEAM) {
      return PLAN_LIMITS.TEAM;
    }

    if (entitlement.identifier === ENTITLEMENT_IDENTIFIERS.ENTERPRISE) {
      return PLAN_LIMITS.ENTERPRISE;
    }

    return PLAN_LIMITS.DEFAULT;
  }, [entitlement, plans, isConfigured, platform]);

  useEffect(() => {
    configure();
  }, [configure]);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  useEffect(() => {
    checkEligibilityForTrial();
  }, [checkEligibilityForTrial]);

  useEffect(() => {
    return () => {
      setLoading(false);
      setPurchasing(false);
    };
  }, []);

  useEffect(() => {
    if (authenticated && user?.id) {
      login(user.id);
    } else {
      logout();
    }
  }, [authenticated, user, login, logout]);

  useEffect(() => {
    return () => {
      if (customerInfoListener) {
        Purchases.removeCustomerInfoUpdateListener({
          listenerToRemove: customerInfoListener
        });
      }
    };
  }, [customerInfoListener]);

  useEffect(() => {
    return () => {
      setIsSubscribed(false);
      setEntitlements({
        all: {},
        active: {},
        verification: 'NOT_REQUESTED' as VERIFICATION_RESULT
      });
      setIsEligibleForTrial(false);
      setIsConfigured(false);
      setPlans([]);
      setSelectedPlan(undefined);
      setLoading(false);
      setPurchasing(false);
    };
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        loading,
        purchasing,
        isSubscribed,
        checkEligibilityForTrial,
        isEligibleForTrial,
        login,
        logout,
        plans,
        selectedPlan,
        setSelectedPlan,
        purchase,
        restore,
        profileCountLimit,
        entitlement
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error(
      'useSubscription must be used within a SubscriptionProvider'
    );
  }
  return context;
};
