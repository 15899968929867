import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useNetwork } from '@/contexts/NetworkContext';
import { Posthog } from '@capawesome/capacitor-posthog';

interface TrackerContextProps {
  initialized: boolean;
  captureEvent: ({
    event,
    properties
  }: {
    event: string;
    properties?: { [key: string]: string };
  }) => void;
  identifyUser: ({
    distinctId,
    userProperties
  }: {
    distinctId: string;
    userProperties?: { [key: string]: string };
  }) => void;
  setUserProperties: (userProperties: { [key: string]: string }) => void;
  resetTracker: () => Promise<void>;
}

const TrackerContext = createContext<TrackerContextProps | undefined>(
  undefined
);

export const TrackerProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { connected } = useNetwork();
  const [initialized, setInitialized] = useState<boolean>(false);

  const captureEvent = useCallback(
    async ({
      event,
      properties
    }: {
      event: string;
      properties?: { [key: string]: string };
    }) => {
      if (!initialized) return;

      await Posthog.capture({ event, properties });
    },
    [initialized]
  );

  const identifyUser = useCallback(
    async ({
      distinctId,
      userProperties
    }: {
      distinctId: string;
      userProperties?: { [key: string]: string };
    }) => {
      if (!initialized) return;

      await Posthog.identify({
        distinctId,
        userProperties
      });
    },
    [initialized]
  );

  const setUserProperties = useCallback(
    async (userProperties: { [key: string]: string }) => {
      if (!initialized) return;

      await Posthog.capture({
        event: '$set',
        properties: { $set: userProperties }
      });
    },
    [initialized]
  );

  const resetTracker = useCallback(async () => {
    if (!initialized) return;

    await Posthog.reset();
  }, [initialized]);

  useEffect(() => {
    const initializePostHog = async () => {
      if (connected && !initialized) {
        await Posthog.setup({
          apiKey: import.meta.env.VITE_POSTHOG_KEY,
          host: import.meta.env.VITE_POSTHOG_HOST
        });
        setInitialized(true);
      }
    };

    initializePostHog();
  }, [connected, initialized]);

  useEffect(() => {
    return () => {
      setInitialized(false);
    };
  }, []);

  return (
    <TrackerContext.Provider
      value={{
        initialized,
        captureEvent,
        identifyUser,
        setUserProperties,
        resetTracker
      }}
    >
      {children}
    </TrackerContext.Provider>
  );
};

export const useTracker = (): TrackerContextProps => {
  const context = useContext(TrackerContext);
  if (!context) {
    throw new Error('useTracker must be used within a TrackerProvider');
  }
  return context;
};
