import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useDentist } from '@/contexts/DentistContext';
import {
  decodeWeeklyReportData,
  getLatestWeeklyReportDate,
  getWeeklyReportByDateAndDentistId,
  getWeeklyReportDates
} from '@/data/WeeklyReports';
import useToast from '@/hooks/useToast';
import {
  AVERAGE_REVENUE_PER_APPOINTMENT,
  WEEKLY_REPORT_DEFAULT_DENTIST
} from '@/utils/constants';
import { AppointmentsByDay, WeeklyReportData } from '@/utils/types';
import moment from 'moment';

interface WeeklyReportsContextType {
  selectedStartDate: string | null;
  setSelectedStartDate: (date: string | null) => void;
  selectedDentist: string | null;
  setSelectedDentist: (dentist: string | null) => void;
  dentists: string[];
  weeklyReportData: WeeklyReportData | null;
  loading: boolean;
  loaded: boolean;
  estimatedRevenue: number;
  appointmentsPerDay: AppointmentsByDay;
  totalAppointments: number;
  noShowRate: number;
  lateRate: number;
  startDates: string[];
  chartData: { Day: string; Appointments: number }[];
}

const WeeklyReportsContext = createContext<
  WeeklyReportsContextType | undefined
>(undefined);

export const WeeklyReportsProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const displayToast = useToast();
  const { selectedDentist: dentist } = useDentist();
  const dentistId = dentist?.id;

  const [startDates, setStartDates] = useState<string[]>([]);
  const [dentists, setDentists] = useState<string[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(
    null
  );
  const [selectedDentist, setSelectedDentist] = useState<string | null>(null);
  const [weeklyReportData, setWeeklyReportData] =
    useState<WeeklyReportData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const fetchLatestWeeklyReportDate = useCallback(async () => {
    if (!dentistId) return;

    setLoading(true);
    try {
      const latestStartDate = await getLatestWeeklyReportDate(dentistId);
      setSelectedStartDate(latestStartDate);
    } catch {
      displayToast({
        message: 'Failed to load the latest weekly report.',
        duration: 3000,
        position: 'bottom'
      });
    } finally {
      setLoaded(true);
      setLoading(false);
    }
  }, [dentistId]);

  const fetchWeeklyReport = useCallback(async () => {
    if (!dentistId || !selectedStartDate) return;

    setLoading(true);
    try {
      const report = await getWeeklyReportByDateAndDentistId(
        dentistId,
        selectedStartDate
      );

      if (report?.encoded_data) {
        const decodedData = await decodeWeeklyReportData(report.encoded_data);
        setWeeklyReportData(decodedData);
        const dentistNames = Object.keys(
          decodedData.reportData?.appointmentsByDentist || {}
        );
        setSelectedDentist(WEEKLY_REPORT_DEFAULT_DENTIST);
        setDentists([
          WEEKLY_REPORT_DEFAULT_DENTIST,
          ...dentistNames
            .sort()
            .filter((dentist) => dentist !== WEEKLY_REPORT_DEFAULT_DENTIST)
        ]);
        setLoaded(true);
      } else {
        displayToast({
          message: 'No weekly report found for the selected date.',
          duration: 3000,
          position: 'bottom'
        });
      }
    } catch {
      displayToast({
        message: 'Failed to load the weekly report.',
        duration: 3000,
        position: 'bottom'
      });
    } finally {
      setLoading(false);
    }
  }, [dentistId, selectedStartDate]);

  const fetchDates = useCallback(async () => {
    if (!dentistId) return;

    try {
      const reportDates = await getWeeklyReportDates(dentistId);
      setStartDates(
        reportDates
          .map((report) => report.start_date)
          .sort((a, b) => moment(b).diff(moment(a)))
      );
    } catch {
      displayToast({
        message: 'Failed to load weekly report dates.',
        duration: 3000,
        position: 'bottom'
      });
    } finally {
      setLoaded(true);
    }
  }, [dentistId]);

  const appointmentsPerDay = useMemo(() => {
    if (!selectedDentist) return {};

    return (
      weeklyReportData?.reportData?.appointmentsByDentist?.[selectedDentist] ||
      {}
    );
  }, [weeklyReportData, selectedDentist]);

  const chartData = useMemo(() => {
    const allDays = Array.from({ length: 7 }, (_, i) => {
      const date = moment().startOf('week').add(i, 'days');
      return date.format('dddd');
    });

    return allDays.map((day) => ({
      Day: day,
      Appointments: appointmentsPerDay[day] || 0
    }));
  }, [appointmentsPerDay]);

  const totalAppointments = useMemo(() => {
    return Object.values(appointmentsPerDay).reduce(
      (sum, appointments) => sum + appointments,
      0
    );
  }, [appointmentsPerDay]);

  const estimatedRevenue = useMemo(() => {
    return totalAppointments * AVERAGE_REVENUE_PER_APPOINTMENT;
  }, [totalAppointments]);

  const noShowRate = useMemo(() => {
    if (!selectedDentist) return 0;

    if (selectedDentist === WEEKLY_REPORT_DEFAULT_DENTIST) {
      const totalNoShowRate = Object.keys(
        weeklyReportData?.reportData?.noShowRatesByDentist || {}
      ).reduce((noShowRate: number, dentistKey: string) => {
        return (
          noShowRate +
          (weeklyReportData?.reportData?.noShowRatesByDentist[dentistKey] || 0)
        );
      }, 0);
      const count = Object.keys(
        weeklyReportData?.reportData?.noShowRatesByDentist || {}
      ).length;
      return count > 0 ? parseFloat((totalNoShowRate / count).toFixed(2)) : 0;
    }

    return parseFloat(
      (
        weeklyReportData?.reportData?.noShowRatesByDentist?.[selectedDentist] ||
        0
      ).toFixed(2)
    );
  }, [weeklyReportData, selectedDentist]);

  const lateRate = useMemo(() => {
    if (!selectedDentist) return 0;

    if (selectedDentist === WEEKLY_REPORT_DEFAULT_DENTIST) {
      const totalLateRate = Object.keys(
        weeklyReportData?.reportData?.lateRatesByDentist || {}
      ).reduce((lateRate: number, dentistKey: string) => {
        return (
          lateRate +
          (weeklyReportData?.reportData?.lateRatesByDentist[dentistKey] || 0)
        );
      }, 0);
      const count = Object.keys(
        weeklyReportData?.reportData?.lateRatesByDentist || {}
      ).length;
      return count > 0 ? parseFloat((totalLateRate / count).toFixed(2)) : 0;
    }

    return parseFloat(
      (
        weeklyReportData?.reportData?.lateRatesByDentist?.[selectedDentist] || 0
      ).toFixed(2)
    );
  }, [weeklyReportData, selectedDentist]);

  useEffect(() => {
    fetchLatestWeeklyReportDate();
  }, [fetchLatestWeeklyReportDate]);

  useEffect(() => {
    fetchDates();
  }, [fetchDates]);

  useEffect(() => {
    fetchWeeklyReport();
  }, [fetchWeeklyReport]);

  useEffect(() => {
    return () => {
      setWeeklyReportData(null);
      setDentists([]);
      setSelectedDentist(null);
      setStartDates([]);
      setSelectedStartDate(null);
      setLoading(false);
      setLoaded(false);
    };
  }, []);

  return (
    <WeeklyReportsContext.Provider
      value={{
        selectedStartDate,
        setSelectedStartDate,
        selectedDentist,
        setSelectedDentist,
        dentists,
        weeklyReportData,
        loading,
        loaded,
        estimatedRevenue,
        appointmentsPerDay,
        totalAppointments,
        noShowRate,
        lateRate,
        startDates,
        chartData
      }}
    >
      {children}
    </WeeklyReportsContext.Provider>
  );
};

export const useWeeklyReports = () => {
  const context = useContext(WeeklyReportsContext);
  if (context === undefined) {
    throw new Error(
      'useWeeklyReports must be used within a WeeklyReportsProvider'
    );
  }
  return context;
};
