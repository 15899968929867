import {
  IonButton,
  IonIcon,
  IonInput,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText
} from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Spacer from '@/components/common/spacer/Spacer';
import SelectModal from '@/components/modals/selectModal/SelectModal';
import { useAddDentist } from '@/contexts/AddDentistContext';
import { Service, getServiceOptions } from '@/data/Services';
import useToast from '@/hooks/useToast';
import {
  SERVICE_DURATION_OPTIONS,
  SERVICE_JOIN_SEPARATOR
} from '@/utils/constants';
import { checkmarkCircleOutline } from 'ionicons/icons';

import './Services.css';

interface ServicesProps {
  presentingElement?: HTMLElement | undefined;
}

const Services: React.FC<ServicesProps> = ({ presentingElement }) => {
  const { services, setServices, onCreateServices, onSkip } = useAddDentist();
  const displayToast = useToast();

  const [hasError, setHasError] = useState<boolean>(false);
  const [lastSavedServices, setLastSavedServices] = useState<Service[]>([]);
  const [serviceOptions, setServiceOptions] = useState<string[]>([]);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchServiceOptions = async () => {
      try {
        const options = await getServiceOptions();
        setServiceOptions(options);
      } catch {
        displayToast({
          message: `Please check your internet connection.`,
          duration: 3000,
          position: 'bottom'
        });
      }
    };
    fetchServiceOptions();
  }, []);

  const copy = {
    subHeader: 'SERVICES',
    header: (
      <>
        Select the <IonText color="primary">services</IonText> you offer
      </>
    ),
    message: `Please check all services you offer and indicate the estimated duration of each service.`,
    buttonText: 'Done',
    skipText: 'Skip',
    inputLabel: 'Services',
    inputPlaceholder: 'Example: Braces Adjustment',
    modalTitle: (
      <>
        Select <IonText color="primary">services</IonText>
      </>
    )
  };

  const isSelected = useCallback(
    (value: string) => services.some((service) => service.name === value),
    [services]
  );

  const isPreSelected = useCallback(
    (value: string) =>
      lastSavedServices.some((service) => service.name === value),
    [lastSavedServices]
  );

  const onSelect = useCallback(
    (value: string, selected: boolean) => {
      setServices((prevServices) =>
        selected
          ? prevServices.filter((service) => service.name !== value)
          : [...prevServices, { name: value, duration: 30 }]
      );
    },
    [setServices]
  );

  const onClose = useCallback(() => {
    setIsSelectModalOpen(false);
    setLastSavedServices(services);
  }, [services]);

  const handleDurationChange = useCallback(
    (service: Service, value: number) => {
      setServices((prevServices) =>
        prevServices.map((s) =>
          s.name === service.name ? { ...s, duration: value } : s
        )
      );
    },
    [setServices]
  );

  const onNext = useCallback(() => {
    if (services.length > 0) {
      setHasError(false);
      onCreateServices(
        services.map((service) =>
          service.duration ? service : { ...service, duration: 30 }
        )
      );
    } else {
      setHasError(true);
      displayToast({
        message: 'Please select a valid service.',
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
    }
  }, [services, onCreateServices]);

  return (
    <div id="services">
      <Spacer top={32} bottom={24}>
        <h4 id="subHeader">{copy.subHeader}</h4>
        <h1 id="header">{copy.header}</h1>
        <p id="message">{copy.message}</p>
      </Spacer>
      <div id="form">
        <div className={`input ${hasError ? 'error' : ''}`}>
          <IonInput
            id="serviceInput"
            label={copy.inputLabel}
            labelPlacement="stacked"
            value={services
              .map((service) => service.name)
              .join(SERVICE_JOIN_SEPARATOR)}
            placeholder={copy.inputPlaceholder}
            onClick={() => {
              setHasError(false);
              setIsSelectModalOpen(true);
            }}
            readonly
          />
        </div>
      </div>
      {services.length > 0 && (
        <Spacer top={24}>
          <div id="selectedServices">
            {services
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((service, index) => (
                <div key={index} className="selectedService">
                  <p className="serviceLabel">{service.name}</p>
                  <div className="input">
                    <IonSelect
                      label="Estimated Duration"
                      labelPlacement="stacked"
                      placeholder="Select duration"
                      value={service.duration}
                      onIonChange={(e: CustomEvent) =>
                        handleDurationChange(service, e.detail.value)
                      }
                    >
                      {SERVICE_DURATION_OPTIONS.map((option) => (
                        <IonSelectOption
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                </div>
              ))}
          </div>
        </Spacer>
      )}
      <Spacer bottom={172} />
      <ButtonContainer>
        <IonButton
          id="nextButton"
          onClick={onNext}
          expand="block"
          fill="solid"
          size="default"
          color="primary"
          strong={true}
        >
          <IonIcon slot="start" icon={checkmarkCircleOutline}></IonIcon>
          {copy.buttonText}
        </IonButton>
        <IonButton
          id="skipButton"
          onClick={onSkip}
          expand="block"
          fill="clear"
          size="default"
          color="primary"
          strong={true}
        >
          {copy.skipText}
        </IonButton>
      </ButtonContainer>

      <SelectModal
        isOpen={isSelectModalOpen}
        presentingElement={presentingElement}
        title={copy.modalTitle}
        options={serviceOptions}
        isSelected={isSelected}
        isPreSelected={isPreSelected}
        onSelect={onSelect}
        onClose={onClose}
        multiple={true}
        showOthers={false}
      />
    </div>
  );
};

export default Services;
