import { IonContent } from '@ionic/react';
import { IonPage } from '@ionic/react';
import React from 'react';

import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import Benefits from '@/components/paywall/benefits/Benefits';
import Buttons from '@/components/paywall/buttons/Buttons';
import Header from '@/components/paywall/header/Header';
import Plans from '@/components/paywall/plans/Plans';

import './Paywall.css';

const Paywall: React.FC = () => {
  return (
    <IonPage id="paywall">
      <IonContent>
        <Padding>
          <Logo />
          <Spacer top={8} />
          <Header />
          <Spacer top={32} bottom={38}>
            <Benefits />
          </Spacer>
          <Plans />
          <Spacer top={166} />
          <Buttons />
        </Padding>
      </IonContent>
    </IonPage>
  );
};

export default Paywall;
