import { IonBadge, IonIcon } from '@ionic/react';

import {
  REQUEST_STATUS_ACTIVE_ICONS,
  REQUEST_STATUS_ICONS
} from '@/utils/constants';
import capitalize from '@/utils/helpers/capitalize';

interface StatusOptionsProps {
  selectedStatus: string;
  onStatusChange: (status: string) => void;
  statusOptions: string[];
  pendingRequestsCount: number;
}

const StatusOptions: React.FC<StatusOptionsProps> = ({
  selectedStatus,
  onStatusChange,
  statusOptions,
  pendingRequestsCount
}) => {
  return (
    <div id="statusOptions">
      {statusOptions.map((status: string) => (
        <div
          key={status}
          className={`statusOption ${selectedStatus === status ? 'selected' : ''}`}
          onClick={() => onStatusChange(status)}
        >
          <IonIcon
            icon={
              selectedStatus === status
                ? REQUEST_STATUS_ACTIVE_ICONS[status]
                : REQUEST_STATUS_ICONS[status]
            }
          />
          <p className="label">{capitalize(status)}</p>
          {status === 'pending' && pendingRequestsCount > 0 && (
            <IonBadge color="danger">{pendingRequestsCount}</IonBadge>
          )}
        </div>
      ))}
    </div>
  );
};

export default StatusOptions;
