import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonLoading,
  IonModal,
  IonText
} from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import SelectModal from '@/components/modals/selectModal/SelectModal';
import { useDentist } from '@/contexts/DentistContext';
import { updateDentist } from '@/data/Dentists';
import useToast from '@/hooks/useToast';
import {
  SPECIALIZATION_JOIN_SEPARATOR,
  SPECIALIZATION_OPTIONS
} from '@/utils/constants';
import { arrowBackOutline, checkmarkCircleOutline } from 'ionicons/icons';

import './UpdateSpecializations.css';

interface UpdateSpecializationsProps {
  isOpen: boolean;
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
}

const UpdateSpecializations: React.FC<UpdateSpecializationsProps> = ({
  isOpen,
  onClose,
  presentingElement
}) => {
  const displayToast = useToast();
  const { selectedDentist: dentist, setSelectedDentist } = useDentist();

  const [specializations, setSpecializations] = useState<string[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [lastSavedSpecializations, setLastSavedSpecializations] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState<boolean>(false);
  const [selectModalPresentingElement, setSelectModalPresentingElement] =
    useState<HTMLElement | undefined>(undefined);

  const modal = useRef<HTMLIonModalElement | null>(null);

  useEffect(() => {
    setSelectModalPresentingElement(modal.current ?? undefined);
  }, [modal.current]);

  useEffect(() => {
    if (dentist?.specialization) {
      const fetchedSpecializations = dentist.specialization.split(
        SPECIALIZATION_JOIN_SEPARATOR
      );
      setSpecializations(fetchedSpecializations);
      setLastSavedSpecializations(fetchedSpecializations);
    }
  }, [dentist?.specialization]);

  const copy = {
    subHeader: 'SPECIALIZATIONS',
    header: (
      <>
        Manage your <IonText color="primary">specializations</IonText>
      </>
    ),
    message: `Please select all of your specializations.`,
    buttonText: 'Update',
    inputLabel: 'Specializations',
    inputPlaceholder: 'Example: Orthodontics',
    modalTitle: (
      <>
        Select <IonText color="primary">specializations</IonText>
      </>
    )
  };

  const isSelected = useCallback(
    (value: string) => specializations.includes(value),
    [specializations]
  );

  const isPreSelected = useCallback(
    (value: string) => lastSavedSpecializations.includes(value),
    [lastSavedSpecializations]
  );

  const onSelect = useCallback(
    (value: string, selected: boolean) => {
      setSpecializations((prevSpecializations) =>
        selected
          ? prevSpecializations
              .filter((specialization) => specialization !== value)
              .sort()
          : [...prevSpecializations, value].sort()
      );
    },
    [setSpecializations]
  );

  const onCloseSelectModal = useCallback(() => {
    setIsSelectModalOpen(false);
    setLastSavedSpecializations(specializations);
  }, [specializations]);

  const onNext = useCallback(async () => {
    if (specializations.length > 0 && dentist?.id) {
      setHasError(false);
      try {
        setLoading(true);
        await updateDentist({
          dentistId: dentist?.id,
          dentist: {
            ...dentist,
            specialization: specializations.join(SPECIALIZATION_JOIN_SEPARATOR)
          }
        });
        setSelectedDentist({
          ...dentist,
          specialization: specializations.join(SPECIALIZATION_JOIN_SEPARATOR)
        });
        displayToast({
          message: 'Specializations updated successfully.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
        setLoading(false);
        onClose();
      } catch {
        setLoading(false);
        displayToast({
          message: 'Something went wrong. Please try again.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    } else {
      setHasError(true);
      displayToast({
        message: 'Please select a valid specialization.',
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'tabBar'
      });
    }
  }, [specializations, dentist?.id, onClose]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
      ref={modal}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <div id="updateSpecializations">
            <Logo leftIcon={arrowBackOutline} onClickLeftIcon={onClose} />
            <Spacer top={0} bottom={24}>
              <h4 id="subHeader">{copy.subHeader}</h4>
              <h1 id="header">{copy.header}</h1>
              <p id="message">{copy.message}</p>
            </Spacer>

            <Spacer bottom={124}>
              <div id="form">
                <div className={`input ${hasError ? 'error' : ''}`}>
                  <IonInput
                    id="specializationInput"
                    label={copy.inputLabel}
                    labelPlacement="stacked"
                    value={specializations.join(SPECIALIZATION_JOIN_SEPARATOR)}
                    placeholder={copy.inputPlaceholder}
                    onClick={() => {
                      setHasError(false);
                      setIsSelectModalOpen(true);
                    }}
                    readonly
                  />
                </div>
              </div>
            </Spacer>

            <ButtonContainer>
              <IonButton
                id="nextButton"
                onClick={onNext}
                expand="block"
                fill="solid"
                size="default"
                color="primary"
                strong={true}
                disabled={loading}
              >
                <IonIcon slot="start" icon={checkmarkCircleOutline}></IonIcon>
                {copy.buttonText}
              </IonButton>
            </ButtonContainer>
          </div>
        </Padding>
        <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
        <SelectModal
          isOpen={isSelectModalOpen}
          presentingElement={selectModalPresentingElement}
          title={copy.modalTitle}
          options={Object.values(SPECIALIZATION_OPTIONS)}
          isSelected={isSelected}
          isPreSelected={isPreSelected}
          onSelect={onSelect}
          onClose={onCloseSelectModal}
          multiple={true}
          showOthers={false}
        />
      </IonContent>
    </IonModal>
  );
};

export default UpdateSpecializations;
