import { IonButton, IonRouterLink } from '@ionic/react';
import React, { useCallback } from 'react';

import { Browser } from '@capacitor/browser';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Spacer from '@/components/common/spacer/Spacer';
import { useSubscription } from '@/contexts/SubscriptionContext';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@/utils/constants';
import { PLAN_PRICES_STRING } from '@/utils/subscriptions';

import './Buttons.css';

const Buttons: React.FC = () => {
  const { isEligibleForTrial, selectedPlan, purchase, restore } =
    useSubscription();

  const getPromptText = useCallback(() => {
    if (isEligibleForTrial) {
      return 'No charges yet. Cancel anytime.';
    }

    return 'Subscribe to continue using Odonto.';
  }, [isEligibleForTrial]);

  const getButtonText = useCallback(() => {
    if (isEligibleForTrial) {
      return 'Start 1-month free trial';
    }

    if (!selectedPlan) {
      return 'Continue';
    }

    return `₱${PLAN_PRICES_STRING[selectedPlan.identifier] ?? '999'}/month`;
  }, [isEligibleForTrial, selectedPlan]);

  const onTerms = () => {
    Browser.open({ url: TERMS_OF_SERVICE_URL });
  };

  const onPrivacy = () => {
    Browser.open({ url: PRIVACY_POLICY_URL });
  };

  const copy = {
    restore: 'Restore',
    terms: 'Terms',
    privacy: 'Privacy'
  };

  return (
    <ButtonContainer translucent={false}>
      <p className="prompt">{getPromptText()}</p>
      <Spacer top={8} />
      <IonButton
        id="nextButton"
        onClick={purchase}
        expand="block"
        fill="solid"
        size="default"
        color="primary"
        strong={true}
      >
        {getButtonText()}
      </IonButton>
      <div className="links">
        <IonRouterLink id="restoreLink" color="dark" onClick={restore}>
          {copy.restore}
        </IonRouterLink>
        <span>•</span>
        <IonRouterLink id="termsLink" color="dark" onClick={onTerms}>
          {copy.terms}
        </IonRouterLink>
        <span>•</span>
        <IonRouterLink id="privacyLink" color="dark" onClick={onPrivacy}>
          {copy.privacy}
        </IonRouterLink>
      </div>
    </ButtonContainer>
  );
};

export default Buttons;
