import { useIonActionSheet } from '@ionic/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useAuth } from '@/contexts/AuthenticationContext';
import { useClinic } from '@/contexts/ClinicContext';
import { getClinic } from '@/data/Clinics';
import {
  DentistHour,
  getDentistHoursByDentistId,
  updateDentistHour
} from '@/data/DentistHours';
import { getUnreadDentistNotificationsByDentistId } from '@/data/DentistNotifications';
import {
  Dentist,
  getDentistsByClinicId,
  getDentistsByUserId
} from '@/data/Dentists';
import { getRequestsByDentistIdAndStatus } from '@/data/Requests';
import useToast from '@/hooks/useToast';
import {
  REQUEST_EXPIRATION_BUFFER_IN_MINUTES,
  REQUEST_STATUS_OPTIONS
} from '@/utils/constants';
import { formatName } from '@/utils/helpers/formatName';
import { Storage } from '@ionic/storage';
import moment from 'moment';

interface DentistContextType {
  dentists: Dentist[];
  setDentists: React.Dispatch<React.SetStateAction<Dentist[]>>;
  selectedDentist: Dentist | null;
  setSelectedDentist: (dentist: Dentist | null) => void;
  dentistHours: DentistHour[];
  selectDentist: (dentist: Dentist | null) => Promise<void>;
  updateHours: (hours: DentistHour[]) => Promise<void>;
  fetchDentists: () => Promise<void>;
  loading: boolean;
  loaded: boolean;
  onSwitch: () => void;
}

const DentistContext = createContext<DentistContextType | undefined>(undefined);

interface DentistProviderProps {
  children: React.ReactNode;
}

export const DentistProvider: React.FC<DentistProviderProps> = ({
  children
}) => {
  const storage = new Storage();
  const { user } = useAuth();
  const { selectedClinic: clinic, selectClinic, isClinic } = useClinic();
  const displayToast = useToast();

  const [dentists, setDentists] = useState<Dentist[]>([]);
  const [selectedDentist, setSelectedDentist] = useState<Dentist | null>(null);
  const [dentistHours, setDentistHours] = useState<DentistHour[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [present] = useIonActionSheet();

  const selectDentist = useCallback(
    async (dentist: Dentist | null) => {
      if (isClinic === undefined && dentist !== null) return;

      if (isClinic) {
        if (dentist === null) {
          setLoaded(false);
        }

        setSelectedDentist(dentist);
        await storage.create();
        await storage.set(
          'selectedDentist',
          dentist ? JSON.stringify(dentist) : null
        );
      } else {
        if (dentist === null) {
          selectClinic(null);
          setLoaded(false);
        } else {
          const clinicData = await getClinic(dentist?.clinic_id!);
          if (clinicData) selectClinic(clinicData);
        }

        setSelectedDentist(dentist);
        await storage.create();
        await storage.set(
          'selectedDentist',
          dentist ? JSON.stringify(dentist) : null
        );
      }
    },
    [storage, isClinic, selectClinic]
  );

  const updateHours = useCallback(
    async (hours: DentistHour[]) => {
      if (selectedDentist) {
        try {
          const existingHours = await getDentistHoursByDentistId(
            selectedDentist.id!
          );
          const updatedHours = await Promise.all(
            hours.map(async (hour) => {
              const existingHour = existingHours.find(
                (existingHour) => existingHour.day === hour.day
              );
              return existingHour
                ? updateDentistHour({
                    id: existingHour.id!,
                    dentistHour: hour
                  })
                : null;
            })
          );
          setDentistHours(
            updatedHours.filter((hour) => hour !== null) as DentistHour[]
          );
          displayToast({
            message: 'Working hours updated successfully.',
            duration: 5000,
            position: 'bottom',
            positionAnchor: 'tabBar'
          });
        } catch {
          displayToast({
            message:
              'Failed to update working hours. Please check your internet connection and try again.',
            duration: 5000,
            position: 'bottom',
            positionAnchor: 'tabBar'
          });
        }
      }
    },
    [selectedDentist]
  );

  const fetchDentistBadge = useCallback(
    async ({ dentistId }: { dentistId: string | undefined }) => {
      if (!dentistId) return;

      try {
        const fetchedNotifications =
          await getUnreadDentistNotificationsByDentistId(dentistId);

        const expirationTime = moment().add(
          REQUEST_EXPIRATION_BUFFER_IN_MINUTES,
          'minutes'
        );

        const pendingRequests = await getRequestsByDentistIdAndStatus(
          dentistId,
          REQUEST_STATUS_OPTIONS.PENDING
        );

        const validNotifications = fetchedNotifications.filter(
          (notification) => {
            const request = pendingRequests.find(
              (request) => request.id === notification.request_id
            );

            if (!request) return true;

            const requestStartTime = moment(
              `${request.date} ${request.start_time}`,
              'YYYY-MM-DD HH:mm'
            );

            const isRequestExpired = requestStartTime.isBefore(expirationTime);

            return !isRequestExpired;
          }
        );

        return validNotifications.length;
      } catch {
        displayToast({
          message: `Please check your internet connection.`,
          duration: 3000,
          position: 'bottom'
        });
      }
    },
    []
  );

  const formatDentistsData = useCallback(
    async (dentists: Dentist[]) => {
      return await Promise.all(
        dentists
          .sort((a, b) => {
            const nameComparison = a.first_name.localeCompare(b.first_name);
            if (nameComparison !== 0) return nameComparison;
            return a.clinic?.name?.localeCompare(b.clinic?.name || '') || 0;
          })
          .map(async (dentist) => ({
            ...dentist,
            badge: await fetchDentistBadge({ dentistId: dentist.id })
          }))
      );
    },
    [fetchDentistBadge]
  );

  const fetchDentists = useCallback(async () => {
    if (isClinic === undefined) return;

    try {
      setLoading(true);
      if (clinic?.id && isClinic) {
        const data = await getDentistsByClinicId(clinic.id);
        if (data.length > 0) {
          const dentists = await formatDentistsData(data);
          setDentists(dentists);
        }
      } else if (user?.id) {
        const data = await getDentistsByUserId(user?.id);
        if (data.length > 0) {
          const dentists = await formatDentistsData(data);
          setDentists(dentists);
        }
      }
      setLoaded(true);
    } catch {
      displayToast({
        message: `Please check your internet connection.`,
        duration: 3000,
        position: 'bottom'
      });
    } finally {
      setLoading(false);
    }
  }, [clinic?.id, user?.id, isClinic, formatDentistsData]);

  const onSwitch = useCallback(() => {
    if (isClinic === undefined) return;

    const options = isClinic
      ? dentists.map((dentist) => ({
          text: `Dr. ${formatName(dentist.first_name)} ${formatName(dentist.last_name)}`,
          handler: () => {
            selectDentist(dentist);
            displayToast({
              message: `Switched to Dr. ${formatName(dentist.first_name)}`,
              duration: 2400,
              position: 'bottom',
              positionAnchor: 'tabBar'
            });
          }
        }))
      : dentists.map((dentist) => ({
          text: dentist?.clinic?.name ? formatName(dentist.clinic.name) : '',
          handler: () => {
            selectDentist(dentist);
            displayToast({
              message: `Switched to ${dentist?.clinic?.name ? formatName(dentist.clinic.name) : ''}`,
              duration: 2400,
              position: 'bottom',
              positionAnchor: 'tabBar'
            });
          }
        }));

    present({
      header: `Switch ${isClinic ? 'Dentist' : 'Clinic'}`,
      subHeader: `Choose a ${isClinic ? 'dentist' : 'clinic'} below`,
      buttons: [
        ...options,
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
  }, [isClinic, dentists, selectDentist, present]);

  const fetchDentistHours = useCallback(async () => {
    if (selectedDentist?.id) {
      try {
        const hours = await getDentistHoursByDentistId(selectedDentist.id);
        setDentistHours(hours);
      } catch {
        displayToast({
          message: `Please check your internet connection.`,
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    }
  }, [selectedDentist?.id]);

  const fetchSelectedDentist = useCallback(async () => {
    if (selectedDentist) return;

    await storage.create();
    const data = await storage.get('selectedDentist');
    if (data) {
      const dentist = JSON.parse(data);
      setSelectedDentist(dentist as Dentist);
    }
  }, [storage, selectedDentist]);

  useEffect(() => {
    fetchSelectedDentist();
  }, [fetchSelectedDentist]);

  useEffect(() => {
    fetchDentistHours();
  }, [fetchDentistHours]);

  useEffect(() => {
    if (!user?.id) selectDentist(null);
  }, [user?.id, selectDentist]);

  useEffect(() => {
    return () => {
      setDentists([]);
      setSelectedDentist(null);
      setDentistHours([]);
      setLoading(false);
      setLoaded(false);
    };
  }, []);

  return (
    <DentistContext.Provider
      value={{
        dentists,
        setDentists,
        selectedDentist,
        setSelectedDentist,
        dentistHours,
        selectDentist,
        updateHours,
        fetchDentists,
        loading,
        loaded,
        onSwitch
      }}
    >
      {children}
    </DentistContext.Provider>
  );
};

export const useDentist = () => {
  const context = useContext(DentistContext);
  if (context === undefined) {
    throw new Error('useDentist must be used within a DentistProvider');
  }
  return context;
};
