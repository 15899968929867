import React, { createContext, useContext, useEffect, useState } from 'react';

import { Network } from '@capacitor/network';

interface NetworkContextProps {
  connected: boolean;
}

const NetworkContext = createContext<NetworkContextProps | undefined>(
  undefined
);

export const NetworkProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [connected, setConnected] = useState<boolean>(true);

  useEffect(() => {
    const checkNetworkStatus = async () => {
      const status = await Network.getStatus();
      setConnected(status.connected);
    };

    const handleNetworkChange = (status: any) => {
      setConnected(status.connected);
    };

    checkNetworkStatus();

    Network.addListener('networkStatusChange', handleNetworkChange);

    return () => {
      setConnected(true);
      Network.removeAllListeners();
    };
  }, []);

  return (
    <NetworkContext.Provider value={{ connected }}>
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetwork = (): NetworkContextProps => {
  const context = useContext(NetworkContext);
  if (!context) {
    throw new Error('useNetwork must be used within a NetworkProvider');
  }
  return context;
};
