import {
  IonButton,
  IonContent,
  IonPage,
  IonSkeletonText,
  NavContext
} from '@ionic/react';
import { useCallback, useContext, useEffect, useState } from 'react';

import { App } from '@capacitor/app';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import AddDentistTile from '@/components/profiles/selectDentist/addDentistTile/AddDentistTile';
import DentistTile from '@/components/profiles/selectDentist/dentistTile/DentistTile';
import DentistTilePlaceholder from '@/components/profiles/selectDentist/dentistTilePlaceholder/DentistTilePlaceholder';
import { useAuth } from '@/contexts/AuthenticationContext';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useSubscription } from '@/contexts/SubscriptionContext';
import { Dentist } from '@/data/Dentists';
import { ROUTES } from '@/utils/routes';

import './SelectDentist.css';

const SelectDentist: React.FC = () => {
  const { navigate } = useContext(NavContext);
  const { signOut, user } = useAuth();
  const {
    isClinic,
    fetchClinic,
    loading: clinicLoading,
    loaded: clinicLoaded
  } = useClinic();
  const {
    dentists,
    selectDentist,
    fetchDentists,
    setDentists,
    loading: dentistsLoading,
    loaded: dentistsLoaded
  } = useDentist();
  const { profileCountLimit, loading: loadingSubscription } = useSubscription();

  const [loading, setLoading] = useState<boolean>(true);

  const copy = {
    prompt: isClinic ? 'Choose a dentist' : 'Choose a clinic',
    addLabel: 'Add Dentist',
    signOutButtonText: 'Sign Out'
  };

  const onSelectDentist = useCallback(
    (dentist: Dentist) => {
      selectDentist(dentist);
      navigate(ROUTES.CALENDAR);
    },
    [navigate, selectDentist]
  );

  const onAddDentist = useCallback(() => {
    navigate(ROUTES.ADD_DENTIST);
  }, [navigate]);

  useEffect(() => {
    const fetchDentistsData = async (force = false) => {
      if (
        (user?.id &&
          loading &&
          !dentistsLoading &&
          !dentistsLoaded &&
          clinicLoaded) ||
        force
      ) {
        setDentists([]);
        await fetchDentists();
        if (isClinic !== undefined) setLoading(false);
      } else if (
        user?.id &&
        loading &&
        dentistsLoaded &&
        clinicLoaded &&
        isClinic !== undefined
      ) {
        setLoading(false);
      }
    };
    fetchDentistsData();

    App.addListener('resume', () => {
      setLoading(true);
      fetchDentistsData(true);
    });

    return () => {
      App.removeAllListeners();
    };
  }, [
    fetchDentists,
    setDentists,
    loading,
    user?.id,
    dentistsLoading,
    dentistsLoaded,
    isClinic,
    clinicLoaded
  ]);

  useEffect(() => {
    const fetchClinicData = async () => {
      if (user?.id && loading && !clinicLoading && !clinicLoaded) {
        await fetchClinic();
      }
    };
    fetchClinicData();
  }, [fetchClinic, loading, user?.id, clinicLoading, clinicLoaded]);

  useEffect(() => {
    return () => {
      setLoading(true);
    };
  }, []);

  return (
    <IonPage id="selectDentist">
      <IonContent>
        <Padding>
          <Logo />
          <Spacer top={12} bottom={102}>
            {loading || loadingSubscription ? (
              <IonSkeletonText id="promptPlaceholder" animated />
            ) : (
              <h1 id="prompt">{copy.prompt}</h1>
            )}
            <Spacer top={24} />
            <div id="dentists">
              {loading || loadingSubscription
                ? [...Array(3)].map((_, index) => (
                    <DentistTilePlaceholder key={index} />
                  ))
                : dentists.map((dentist) => (
                    <DentistTile
                      key={dentist.id}
                      dentist={dentist}
                      onSelectDentist={() => onSelectDentist(dentist)}
                    />
                  ))}
              {isClinic &&
                !loading &&
                profileCountLimit > dentists.length &&
                !loadingSubscription && (
                  <AddDentistTile
                    onAddDentist={onAddDentist}
                    label={copy.addLabel}
                  />
                )}
            </div>
          </Spacer>
          <ButtonContainer>
            <IonButton
              id="signOutButton"
              onClick={signOut}
              expand="block"
              fill="clear"
              size="default"
              color="primary"
              strong={true}
            >
              {copy.signOutButtonText}
            </IonButton>
          </ButtonContainer>
        </Padding>
      </IonContent>
    </IonPage>
  );
};

export default SelectDentist;
